/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import * as S from "./cardSelector.style";

function CardSelector({
  name,
  id,
  value,
  children,
  title,
  text,
  type,
  isChecked,
  onChange,
  className,
}) {
  const labelWidth = useState();
  const labelHeight = useState();
  const labelRef = useRef();
  useEffect(() => {
    const el = labelRef?.current;
    if (labelRef) {
      labelWidth[1](el?.offsetWidth);
      labelHeight[1](el?.offsetHeight);
    }
  }, [labelRef]);
  const RenderCardSelector = useMemo(() => {
    if (type.match(/^checkbox|^radio/g)) {
      return (
        <S.Wrap className={className}>
          <S.Input
            name={name}
            id={id}
            type={type}
            value={value}
            defaultChecked={isChecked}
            onChange={onChange}
          />
          <S.Label
            htmlFor={id}
            w={labelWidth[0]}
            h={labelHeight[0]}
            name={name}
            id={id}
            ref={labelRef}
          >
            <S.LabelContent>
              <S.Title>{title}</S.Title>
              {children && text && <span>choose text or children</span>}
              {text && !children && <S.Text>{text}</S.Text>}
              {children && !text && <>{children}</>}
            </S.LabelContent>
          </S.Label>
        </S.Wrap>
      );
    }
    return <span>set type as checkbox or radio</span>;
  }, [type, labelWidth[0], labelHeight[0]]);

  return RenderCardSelector;
}

export default CardSelector;

CardSelector.defaultProps = {
  name: "",
  id: "",
  value: "",
  title: "",
  text: "",
  isChecked: false,
  className: "",
  children: undefined,
  type: "radio",
};
CardSelector.propTypes = {
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  title: PropTypes.string,
  className: PropTypes.string,
  text: PropTypes.string,
  isChecked: PropTypes.bool,
  type: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.node,
    PropTypes.func,
    PropTypes.string,
  ]),
};
