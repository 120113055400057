const deployments = {
  title: "Deployments",
  newDeployment: "New deployment",
  viewAll: "View all",
  noAccounts: {
    title: "Hey",
    text: "Would you like to create your account?",
    actionTxt: "Create account",
  },
  noDeployments: {
    title: "You have no Deployments yet.",
    text: "Click on Create your Deployment to add your first.",
    actionTxt: "Create your Deployment",
  },
  deployments: {
    accordion: {
      tab_1: {
        table: {
          head: {},
          body: {
            col_1: {
              id: "id",
              slug: "Slug",
              status: "status",
              type: "type",
            },
            col_2: {
              provisioned: "Provisioned",
              provisioning: "Provisioning",
              deleting: "Deleting",
            },
          },
        },
        status: {
          provisioned: "Provisioned",
          provisioning: "Provisioning",
          deleting: "Deleting",
        },
      },
      tab_2: {
        name: "Services",
        table: {
          head: {
            service: "Service",
            link: "Link",
          },
          body: {
            linkTxt: "Open {{ serviceType }}",
          },
        },
      },
    },
    actions: {
      delete: "Delete",
      edit: "Edit",
    },
    alerts: {
      deleting: {
        ask: {
          title: "Deleting deployment",
          text:
            "<p>Do you really want to delete it?</p><p>Type <b>{{ serviceName }}</b> to delete it, or click in cancel.</p>",
          buttons: {
            confirm: "Continue",
            cancel: "Cancel",
          },
        },
        success: {
          msg: "Resource was deleted successfully.",
        },
      },
      errorGet:
        "Wasn't possible to load all deployments from this account. Try again, or call us!",
    },
  },
};

export default deployments;
