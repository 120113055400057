import React, { useState, useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import NavigationViewModel from "./navigation";
import * as S from "./navigation.style";

export default function Navigation(props) {
  const { className } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [subMenu, setSubMenu] = useState(null);

  const vm = NavigationViewModel({ props, setIsOpen, setSubMenu });

  const logoutIcon = Object.create(S.ICON);
  logoutIcon.defaultProps = { type: "Logout" };

  const MapRoutes = useCallback(
    (route) => {
      const translatedName = vm.t(route.i18Selector);
      const Icon = Object.create(S.ICON);
      Icon.defaultProps = { type: route.icon };
      if (route.siblings) {
        return (
          <S.Button
            key={route.id}
            onClick={vm.OpenSubMenu({
              title: translatedName,
              options: route.siblings,
            })}
          >
            <S.MENUITEM icon={Icon}>{translatedName}</S.MENUITEM>
          </S.Button>
        );
      }
      return (
        <S.LINK key={route.id} to={route.path} onClick={() => setIsOpen(false)}>
          <S.MENUITEM icon={Icon}>{translatedName}</S.MENUITEM>
        </S.LINK>
      );
    },
    [vm]
  );

  const renderSubMenuOptions = useMemo(() => {
    if (subMenu) {
      const translate = vm.t(`siblings`, { returnObjects: true });
      return (
        <S.SUBMENUGROUP title={subMenu?.title}>
          {subMenu.options?.map((submenuOpt) => (
            <S.LINK
              key={submenuOpt.id}
              to={submenuOpt.path}
              onClick={() => setIsOpen(false)}
            >
              <S.SUBMENUITEM>{translate[submenuOpt.i18Selector]}</S.SUBMENUITEM>
            </S.LINK>
          ))}
        </S.SUBMENUGROUP>
      );
    }
    return null;
  }, [subMenu, vm]);

  return (
    <S.Navigation className={className}>
      <S.MENU>
        <S.MENUBRAND>
          <S.OGLogo />
        </S.MENUBRAND>
        <S.MENUCONTENT>{vm.contentOptions.map(MapRoutes)}</S.MENUCONTENT>
        <S.MENUBOTTOM>
          {vm.bottomOptions.map(MapRoutes)}
          <S.Button onClick={vm.Logout}>
            <S.MENUITEM icon={logoutIcon}>{vm.t("logout")}</S.MENUITEM>
          </S.Button>
        </S.MENUBOTTOM>
      </S.MENU>
      <S.SUBMENU open={isOpen}>{renderSubMenuOptions}</S.SUBMENU>
    </S.Navigation>
  );
}

Navigation.defaultProps = { className: "" };
Navigation.propTypes = { className: PropTypes.string };
