const manageUsers = {
  backButton: "Back",
  title: {
    add: "Add user",
    edit: "Edit user",
  },

  form: {
    submit: {
      add: "Add user",
      edit: "Save",
    },
    labels: {
      name: "Name",
      email: "E-mail",
      role: "Roles",
    },
    errors: {
      required: "{{ field }} is required.",
      minLen: "At least {{ len }} characters.",
      invalid: "{{ field }} is invalid",
      noOption: "Choose one of the {{ field }}.",
    },
  },
};
export default manageUsers;
