import { v4 as uuidv4 } from "uuid";
import { Modal, modalWarnConfig } from "Utils";
import proxyIcons from "./proxyIcons.json";

export default function PortalViewModel({ element, setElement }) {
  const handleClose = (fn, name) => (e) => {
    e.preventDefault();
    return Modal.fire({
      icon: "warning",
      title: "Closing window",
      text: `Do you really want to close ${name} ?`,
      ...modalWarnConfig,
    }).then(async (res) => {
      if (res.value) {
        if (fn) {
          return fn();
        }
        return setElement(null);
      }
      return null;
    });
  };

  function InitialEffect() {
    (async function Mount() {
      const el = document.createElement("section");
      el.setAttribute("id", uuidv4());
      el.setAttribute("class", "portal");
      return setElement(el);
    })();

    const unMount = () => setElement(null);
    return unMount;
  }

  function PortalEffect() {
    (async function Mount() {
      if (element !== null) {
        return document.body.appendChild(element);
      }
      return null;
    })();

    const unMount = () => {
      if (element !== null) {
        return document.body.removeChild(element);
      }
      return null;
    };
    return unMount;
  }

  return { handleClose, InitialEffect, PortalEffect, proxyIcons };
}
