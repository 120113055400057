import React, { useMemo } from "react";
import PropTypes from "prop-types";
import BackButtonViewModel from "./backButton";
import * as S from "./backButton.style";

export default function BackButton({ text }) {
  const vm = new BackButtonViewModel();
  const buttonContent = useMemo(() => text || "Back", [text]);
  return (
    <S.Button onClick={vm.back}>
      <S.ICON type="ArrowLeft" />
      {buttonContent}
    </S.Button>
  );
}

BackButton.defaultProps = {
  text: "",
};

BackButton.propTypes = {
  text: PropTypes.string,
};
