/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import DropboxViewModel from "./dropBox";
import * as S from "./dropBox.styles";

export default function Dropbox(props) {
  const { options, name, id, selected, placeholder, className } = props;
  const [selectedLabel, setSelectedLabel] = useState(
    () => selected?.label || undefined
  );
  const [selectOptions, setSElectedOptions] = useState(() => options || []);

  const vm = new DropboxViewModel({
    setSelectedLabel,
    setSElectedOptions,
    props,
  });

  useEffect(vm.SearchEffect, [selectedLabel]);

  const RenderInput = useMemo(
    () => (
      <>
        {selectedLabel && <S.SupText>{placeholder}</S.SupText>}
        <S.DropboxInput
          id={id}
          name={name}
          value={selectedLabel}
          placeholder={placeholder}
          onChange={vm.handleSearch}
          onFocus={(e) => e.target.select()}
          style={{ "--searchInputSize": selectedLabel?.length }}
        />
      </>
    ),
    [name, id, placeholder, selectedLabel, vm]
  );

  const RenderOptions = useMemo(
    () =>
      selectOptions.map((option, key) => (
        <S.DropboxItem
          key={option.value + key}
          style={{ "--dropItem": key + 1 }}
          tabindex={key + 1}
        >
          <S.DropboxButton onClick={vm.handleSelect(option)}>
            {option.label}
          </S.DropboxButton>
        </S.DropboxItem>
      )),
    [selectOptions, vm]
  );

  return (
    <S.DropboxWrap className={className}>
      <S.DropboxLabel htmlFor={id}>
        {RenderInput}
        <S.DropArrow />
      </S.DropboxLabel>
      <S.DropboxList>{RenderOptions}</S.DropboxList>
    </S.DropboxWrap>
  );
}

Dropbox.defaultProps = {
  name: `dropbox-${new Date().getTime()}`,
  id: new Date().getTime(),
  placeholder: "Select...",
  className: "",
  selected: {
    label: undefined,
    value: undefined,
  },
};

Dropbox.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ).isRequired,
  name: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  selected: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  className: PropTypes.string,
  placeholder: PropTypes.string,
};
