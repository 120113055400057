import styled from "styled-components";
import { Container, Row, Col } from "react-grid-system";
import { Link } from "react-router-dom";

export const Footer = styled.footer`
  height: 100%;
  ${({ theme }) => theme.xs`
    width: 100vw;
    max-height: 48px;
    background: ${theme.colors?.grey_5};
  `}
`;

export const CONTAINER = styled(Container)`
  height: inherit;
  max-width: 1140px !important;
`;

export const ROW = styled(Row)`
  height: inherit;
  align-items: center !important;
`;
export const COL = styled(Col)`
  ${({ theme }) => theme.xs`

  `}
`;
export const Anchor = styled(Link)`
  ${({ theme }) => theme.xs`
    text-decoration: none;
    line-height: 1.5em;
    font-weight: 700;
    display: block;
    color: ${theme.colors?.grey_2};
  `}
`;

export const CopyTxt = styled.p`
  ${({ theme }) => theme.xs`
    display: block;
    text-align: center;
    color: ${theme.colors?.grey_3};
    ${() => theme.md`
      flex: auto;
      justify-self: flex-start;
    `}
  `};
`;
