import { Api } from "Services";
import { Toast } from "Utils";

import { getUsers, getUsersSuccess, getUsersFailure } from "../ducks/actions";

const { Registry } = new Api();

export const getUsersThunk = (accountid) => (dispatch, getStore) => {
  const { Token: token } = getStore();
  dispatch(getUsers());

  return Registry(token.data?.idToken)
    .get(`/v1/accounts/${accountid}/users`)
    .then(({ data }) => {
      dispatch(getUsersSuccess(data.entries));
    })
    .catch((err) => {
      dispatch(getUsersFailure(err));
    });
};

export const postUser = (
  values,
  { setSubmitting, resetForm },
  history,
  account
) => (dispatch, getStore) => {
  const { Token: token } = getStore();
  const { email, name, role } = values;

  const postUserSuccess = () => {
    Toast.fire({
      icon: "success",
      title: "User added successfully.",
    }).then(() => {
      resetForm();
      setSubmitting(false);
      history.push({ pathname: "/users" });
    });
  };

  const postUserError = () => {
    Toast.fire({
      icon: "Error",
      title: "Something went wrong. Please, try again.",
    }).then(() => {
      setSubmitting(false);
    });
  };

  return Registry(token?.data?.idToken)
    .post(`/v1/accounts/${account?.value}/users`, {
      name,
      email,
      profile_id: role,
    })
    .then(postUserSuccess)
    .catch(postUserError);
};
