/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-one-expression-per-line */
import React, { useMemo } from "react";
import PropTypes from "prop-types";
import * as S from "./cardCheck.style";

export default function CardCheck({
  name,
  id,
  type,
  title,
  icon,
  text,
  value,
  children,
  isChecked,
  onChange,
  className,
}) {
  const RenderCheckcer = useMemo(
    () =>
      type.match(/^checkbox|^radio/s) ? (
        <S.Wrap className={className}>
          <S.Input
            name={name}
            type={type}
            id={id}
            defaultChecked={isChecked}
            defaultValue={value}
            onChange={onChange}
          />
          <S.Label htmlFor={id}>
            <S.Svg viewBox="0 0 16 16">
              <S.Path />
            </S.Svg>
            <S.LabelHeader>
              <S.ICON type={icon} scale="small" />
              <S.LabelTitle>{title}</S.LabelTitle>
            </S.LabelHeader>
            <S.LabelContent>
              {children && text && (
                <span>
                  Please, set <b>text</b> or <b>children</b>{" "}
                </span>
              )}
              {text && !children && <S.PARAGRAPH>{text}</S.PARAGRAPH>}
              {children && !text && <>{children}</>}
            </S.LabelContent>
          </S.Label>
        </S.Wrap>
      ) : (
        <span>set type as checkbox or radio</span>
      ),
    [type]
  );

  return RenderCheckcer;
}

CardCheck.defaultProps = {
  name: "",
  id: "",
  value: "",
  title: "",
  text: "",
  isChecked: false,
  className: "",
  children: undefined,
  icon: "Dot",
  type: "radio",
};
CardCheck.propTypes = {
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  title: PropTypes.string,
  className: PropTypes.string,
  text: PropTypes.string,
  isChecked: PropTypes.bool,
  type: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  icon: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.node,
    PropTypes.func,
    PropTypes.string,
  ]),
};
