const mLeft = `
    &.ml4{margin-left: 4px}
    &.ml8{margin-left: 8px}
    &.ml12{margin-left: 12px}
    &.ml16{margin-left: 16px}
    &.ml20{margin-left: 20px}
    &.ml24{margin-left: 24px}
    &.ml28{margin-left: 28px}
    &.ml32{margin-left: 32px}
    &.ml36{margin-left: 36px}
    &.ml40{margin-left: 40px}
    &.ml44{margin-left: 44px}
    &.ml48{margin-left: 48px}
  `;

const mRight = `
    &.mr4{margin-right: 4px}
    &.mr8{margin-right: 8px}
    &.mr12{margin-right: 12px}
    &.mr16{margin-right: 16px}
    &.mr20{margin-right: 20px}
    &.mr24{margin-right: 24px}
    &.mr28{margin-right: 28px}
    &.mr32{margin-right: 32px}
    &.mr36{margin-right: 36px}
    &.mr40{margin-right: 40px}
    &.mr44{margin-right: 44px}
    &.mr48{margin-right: 48px}
  `;

const mTop = `
    &.mTop4{margin-top: 4px}
    &.mTop8{margin-top: 8px}
    &.mTop12{margin-top: 12px}
    &.mTop16{margin-top: 16px}
    &.mTop20{margin-top: 20px}
    &.mTop24{margin-top: 24px}
    &.mTop28{margin-top: 28px}
    &.mTop32{margin-top: 32px}
    &.mTop36{margin-top: 36px}
    &.mTop40{margin-top: 40px}
    &.mTop44{margin-top: 44px}
    &.mTop48{margin-top: 48px}
  `;

const mBottom = `
    &.mBt4{margin-bottom: 4px}
    &.mBt8{margin-bottom: 8px}
    &.mBt12{margin-bottom: 12px}
    &.mBt16{margin-bottom: 16px}
    &.mBt20{margin-bottom: 20px}
    &.mBt24{margin-bottom: 24px}
    &.mBt28{margin-bottom: 28px}
    &.mBt32{margin-bottom: 32px}
    &.mBt36{margin-bottom: 36px}
    &.mBt40{margin-bottom: 40px}
    &.mBt44{margin-bottom: 44px}
    &.mBt48{margin-bottom: 48px}
  `;

const mH = `
    &.mH4{margin: 0 4px}
    &.mH8{margin: 0 8px}
    &.mH12{margin: 0 12px}
    &.mH16{margin: 0 16px}
    &.mH20{margin: 0 20px}
    &.mH24{margin: 0 24px}
    &.mH28{margin: 0 28px}
    &.mH32{margin: 0 32px}
    &.mH36{margin: 0 36px}
    &.mH40{margin: 0 40px}
    &.mH44{margin: 0 44px}
    &.mH48{margin: 0 48px}
  `;

const mV = `
    &.mV4{margin: 4px 0}
    &.mV8{margin: 8px 0}
    &.mV12{margin: 12px 0}
    &.mV16{margin: 16px 0}
    &.mV20{margin: 20px 0}
    &.mV24{margin: 24px 0}
    &.mV28{margin: 28px 0}
    &.mV32{margin: 32px 0}
    &.mV36{margin: 36px 0}
    &.mV40{margin: 40px 0}
    &.mV44{margin: 44px 0}
    &.mV48{margin: 48px 0}
  `;

export default function Margins() {
  return {
    mLeft,
    mRight,
    mTop,
    mBottom,
    mH,
    mV,
  };
}
