const navigation = {
  deployments: "Deployments",
  logout: "cerrar sesión",
  settings: "Ajustes",
  siblings: {
    accounts: "Cuenta",
    users: "Usuarios",
  },
};

export default navigation;
