import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
    ${({ theme }) => `
          :root {
            --primaryFonts: Cairo, 'Open Sans', sans-serif;
            --secondaryFonts: 'Whitney A', 'Whitney B', 'Opens Sans', sans-serif;
          }

        * {
          margin: 0;
          padding: 0;
          outline: 0;
          border: 0;
          appearance: none;
          box-sizing: border-box;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
        }

        html {
          font-family: var(--primaryFonts);
          font-size: 62.5%;
          scroll-behavior: smooth;
        }

        html,body{
          overflow-y: auto;
          overflow-x: hidden;
        }

        body{
          font-size: 1.6rem;
        }

        body, #main{
          color: black;
          width: 100vw;
          height: 100vh;
        }

        input, button, textarea {
            font: 400 1.8rem 'Open Sans', sans-serif;
        }

        button {
            cursor: pointer;
        }

        input[type="text"]{
          padding: 16px 24px;
        }

        input[type="time"]::-webkit-inner-spin-button,
        input[type="time"]::-webkit-outer-spin-button {
          appearance: none !important;
          margin: 0 !important;
        }

        input[type="time"]::-ms-clear { display: none !important; }
        input[type="time"]::-webkit-clear-button,
        input[type="time"]::-webkit-datetime-edit-ampm-field {
          display: none !important;
        }

        input::-webkit-inner-spin-button,
        input::-webkit-outer-spin-button {
          appearance: none !important;
          margin: 0 !important;
        }

        input::-ms-clear { display: none; }
        input::-webkit-clear-button,
        input::-webkit-datetime-edit-ampm-field,
        input::-webkit-calendar-picker-indicator {
          display: none !important;
        }
          /*portal style*/
          .portal{
            position: fixed;
            width: 100vw;
            height: 100vh;
            z-index: 500;
            top: 0;
            left: 0;
            background: ${theme.colors?.white};
          }
         /* RESET sweetalert2 */

         .swal2-popup{
            font-family: var(--secondaryFonts) !important;
            font-size: 100% !important;
         }

         .swal2-title {
           font-family: var(--primaryFonts) !important;
         }
      `}

`;
