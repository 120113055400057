/* eslint-disable no-return-await */
import Keycloak from "keycloak-js";

const KC = new Keycloak({
  realm: process.env.REACT_APP_KC_REALM,
  url: process.env.REACT_APP_KC_DOMAIN,
  clientId: process.env.REACT_APP_KC_CLI,
  reource: process.env.REACT_APP_KC_CLI,
  "confidential-port": process.env.REACT_APP_KC_PORT,
  "public-client": true,
});

export const login = () =>
  KC.login({
    prompt: "login",
    redirectUri: `${window.location.origin}/callback`,
  });

export const logout = () =>
  KC.logout({ redirectUri: `${window.location.origin}/login` });

export const chekcIsAuth = async (otions = { "check-sso": true }) => {
  const isAuth = await KC.init(otions);
  return isAuth;
};

export default KC;
