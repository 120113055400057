import { logger } from "redux-logger";
import Thunk from "redux-thunk";
import SessionStore from "redux-persist/lib/storage/session";
import { persistStore, persistReducer, purgeStoredState } from "redux-persist";
import hardSet from "redux-persist/es/stateReconciler/hardSet";
import { createStore, applyMiddleware, compose } from "redux";
import Reducer from "./Reducer";

const isProd = process.env.REACT_APP_ENV === "production";

const middlewares = [Thunk];
const persistorConfig = {
  key: "root",
  storage: SessionStore,
  stateReconciler: hardSet,
  debug: !isProd,
};

if (!isProd) {
  middlewares.push(logger);
}

const ClearStore = () => purgeStoredState(persistorConfig);
const persistedReducer = persistReducer(persistorConfig, Reducer);
const middlewaresEnhancers = applyMiddleware(...middlewares);
const Store = createStore(persistedReducer, compose(middlewaresEnhancers));
const Persistor = persistStore(Store);

export { Store, Persistor, ClearStore };
