const createDeployment = {
  backButton: "Back",
  title: "Create deployment",
  complement: {
    title: "Space Purpose",
    text:
      "For what purposes will your environments be? Select the Space's usage type so we provide the best Services and Applications.",
  },
  form: {
    submit: "Create deployment",
    fields: {
      errors: {
        required: "Select a deployment.",
      },
    },
  },
};

export default createDeployment;
