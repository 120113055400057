import styled from "styled-components";
import { Icon } from "semantix-design-system/components";

export const Button = styled.button`
  ${({ theme }) => theme.xs`
    margin-bottom: 3.2rem;
    paddind: 1.2rem 2.4rem;
    min-width 56px;
    font-size: 1.6rem;
    display: flex;
    alignitems: center;
    justify-content: space-between;
    color: ${theme.colors?.primary_2};
    background: transparent;
    border: none;
    appearance : none;
    font-family: 'Whitney', 'Opens Sans', sans-serif;
    svg {
      fill: ${theme.colors?.primary_2};
    }
    &:hover{
      cursor: pointer;
      color: ${theme.colors?.primary_3};
      svg {
        fill: ${theme.colors?.primary_3};
      }
    }
`}
`;

export const ICON = styled(Icon)`
  width: 16px;
  height: 16px;
`;
