/* eslint-disable no-nested-ternary */
import styled from "styled-components";
import Images from "semantix-design-system/components/Contents/Images";

const transition = "transition: all 0.25s ease";

export const AccordionWrap = styled.div`
  min-height: inherit;
  width: inherit;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 0 16px;
  position: relative;
  font-family: "Whitney", "Opens Sans", sans-serif;
  ${transition};
`;

export const AccordionTabs = styled.div`
  width: inherit;
  min-height: inherit;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: start;
  ${transition};
`;

export const AccordionButton = styled.button`
  ${({ theme }) => theme.xs`
  min-height: inherit;
  flex: 1 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column wrap;
  background: transparent;
  font-size: 1.6rem;
  font-weight: 600;
  letter-spacing: 0.3px;

  color: ${theme.colors?.primary_2};
  ${transition};

  span,
  small {
    display: block;
    text-align: left;
    text-transform: capitalize;
  }

  small {
    font-size: 1rem;
    font-weight: 500;
  }

  ${() => theme.md`
    flex-flow: row nowrap;
    justify-content: flex-start;
    padding: 0 16px;

    small{
      font-size: 1.2rem;
      margin-left: 24px;
    }
  `}
  &.active{
    color: ${theme.colors?.primary_6};
  }
  `}
`;

export const AccordionContent = styled.div`
  ${({ theme }) => theme.xs`
    min-height: unset;
    height: 0;
    width: 100%;
    max-height: 400px;
    overflow-y: auto;
    padding: 0 16px;
    position: relative;
    font-family: 'Whitney', 'Opens Sans', sans-serif;
    ${transition};

    & > .childContainer {
      width: 100%;
      position: relative;

      & > ul {
        list-style-type: none;
        display: relative;
        width: inherit;
        ${transition};
        & > li {
          list-style-type: none;
          text-indent: 0;
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: inherit;
          line-height: 2em;
          padding-bottom: 4px;
          border-bottom: 1px solid ${theme.colors?.grey_4};

          &:last-of-type {

            border: none;
            margin-bottom: 4px;
          }
          & > div {
            flex: 1 1 50%;
            &:last-of-type {
              text-align: right;
            }
          }
        }
      }

      & > table {
        width: 100%;
        border-collapse: collapse;
        color: ${theme.colors?.grey_1};
        & > thead{
          tr {
           th{
              font-size: 1.8rem;
              font-weight: 600;
              text-align: left;
              line-height: 2.75em;
            }
          }
        }

        & > tbody {
          tr {
            td{
              font-size: 1.6rem;
              font-weight: 400;
              text-align: left;
              line-height: 2.5em;
            }
          }
        }


        &  tr {
          border-bottom: 1px solid ${theme.colors?.grey_4};
          &.col_2{ & > th, & > td { width: calc(100% / 2); }}
          &.col_3{ & > th, & > td { width: calc(100% / 3); }}
          &.col_4{ & > th, & > td { width: calc(100% / 4); }}
          &.col_5{ & > th, & > td { width: calc(100% / 5); }}
          &.col_6{ & > th, & > td { width: calc(100% / 6); }}
          &.col_7{ & > th, & > td { width: calc(100% / 7); }}
          &.col_8{ & > th, & > td { width: calc(100% / 8); }}
          &.col_9{ & > th, & > td { width: calc(100% / 9); }}
          &.col_10{ & > th, & > td { width: calc(100% / 10); }}
          &.col_11{ & > th, & > td { width: calc(100% / 11); }}
          &.col_12{ & > th, & > td { width: calc(100% / 12); }}

        }
      }
    }
    ${() => theme.md`
        & > .childContainer{
          & > ul {
            width: 80%;
          }
        }

    `}
  `}
`;

export const Accordion = styled.div`
  ${({ theme }) => `
    min-height: 72px;
    width: fill-available;
    margin-bottom: 24px;
    position: relative;
    border: 1px solid ${theme.colors?.grey_5};
    border-radius: 4px;
    ${transition};
    &.active {
      border-color: ${theme.colors?.primary_2};
      & ${AccordionWrap} {
        background: ${theme.colors?.grey_6};
      }
      & ${AccordionContent} {
        min-height: 72px;
        height: auto;

        & > .childContainer{
          & > ul{
            margin-top: 24px;
          }
        }
      }
    }
  `}
`;

export const AccordionAlert = styled.small`
  ${({ theme, status, alerts }) => {
    const statusColors = Object.keys(alerts).reduce(
      (acc, curr) => ({
        ...acc,
        [curr]: theme?.colors[alerts[curr]] || theme.colors?.primary_3,
      }),
      {}
    );
    return theme.xs`
        color: ${statusColors[status] || theme.colors?.grey_3};
      `;
  }}
`;

export const AccordionChildTab = styled.span``;
// IMAGES
export const IMAGES = styled(Images)`
  ${({ theme }) => theme.xs`
    margin: 0;
    justify-self: flex-start;
    flex: 0;
    display: flex;
    align-items: center;
    & > img {
      width: 32px;
      height: 32px;
    }

  ${() => theme.md`
      & > img {
        width: 48px;
        height: 48px;
      }

    `}
  `}
`;
// actions
export const ActionsWrap = styled.div`
  position: absolute;
  top: 0;
  display: none;
  background: transparent;
`;

export const ActionsList = styled.div`
  ${({ theme }) => theme.xs`
    box-shadow: 0 0 8px 1px ${theme.colors?.grey_5},  0 0 24px 2px ${
    theme.colors?.grey_5
  };
    &:after {
    content: '';
    display: block;
    position: absolute;
    top: 11px;
    right: 20px;
    width: 10px;
    height: 10px;
    background: #fff;
    border: 50% solid transparent;
    border-right: 1px solid ${theme.colors?.grey_4};
    border-bottom: 1px solid ${theme.colors?.grey_4};
    background: linear-gradient(135deg, transparent 45%, #fff 55%);
    transform: rotate(-135deg);

    z-index: 10;
  }

  ${() => theme.md`
      &:after{
        right: 24px;
      }
  `}
 `}
`;

export const ActionsButton = styled.button`
  ${({ theme, danger, isDisabled }) => `
    width: 100%;
    font-size: 1.6rem;
    min-height: 43px;
    background: #fff;
    padding: 8px 12px;
    text-align: left;
    color: ${
      danger
        ? isDisabled
          ? theme.colors?.error_bg
          : theme.colors?.error
        : isDisabled
        ? theme.colors?.grey_4
        : theme.colors?.grey_1
    };
    z-index:100;
    ${transition};
    &:hover{
      background: ${theme.colors?.grey_6};
    }

  `}
`;

export const Circle = styled.circle.attrs({ r: 6, cy: "50%" })``;

export const ActionsIcon = styled.svg.attrs({ viewBox: "0 0 48 48" })`
  ${({ theme }) => theme.xs`
      fill: ${theme.colors?.primary_2};
      width: 48px;
      height: 24px;
      position: absolute;
      top: -32px;
      right: -16px;

      ${() => theme.md`
        position: relative;
        top: unset;
        right: unset;
        width: 72px;
        height: 36px;
        padding: 0 0 0 8px;
        border-left: 1px solid ${theme.colors?.grey_4};
      `}
    `}
`;

export const ActionsContainer = styled.div`
  ${({ theme }) => theme.xs`
    display: flex;
    align-items: center;
    position: relative;

    &:hover > ${ActionsWrap}{
      display: block;
      min-width: 236px;
      right: -18px;
      top: -24px;
      padding-top: 16px;
      z-index: 200;
      & ${ActionsList}{
        border: 1px solid ${theme.colors?.grey_4};
        border-radius: 8px;
        background: #fff;

       }
    }



    ${() => theme.md`

    &:hover > ${ActionsWrap}{
      display: block;
      min-width: 236px;
      right: 0;
      top: 24px;
      padding-top: 16px;
      z-index: 200;
      & ${ActionsList}{
        border: 1px solid ${theme.colors?.grey_4};
        border-radius: 8px;

        background: #fff;

       }
    }
    `}

    `}
`;
