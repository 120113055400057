import FlexBox from './flexbox.style';
import Margins from './margins.style';
import Paddings from './paddings.style';
import RelativeHeights from './relativeHeights.style';
import TextAlign from './textAlign.style';

const flexbox = FlexBox();
const margins = Margins();
const paddings = Paddings();
const relativeHeights = RelativeHeights();
const textAlign = TextAlign();

export default {
  ...flexbox,
  ...margins,
  ...paddings,
  ...relativeHeights,
  textAlign,
};
