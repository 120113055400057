import * as enUS from "./en-US";
import * as esES from "./es-ES";
import * as ptBR from "./pt-BR";

const Translations = {
  "en-US": { ...enUS },
  "es-ES": { ...esES },
  "pt-BR": { ...ptBR },
};

export default Translations;
