import { useCallback } from "react";

export default function DropboxViewModel({
  props,
  setSelectedLabel,
  setSElectedOptions,
}) {
  const { onSelect, options } = props;

  function SearchEffect() {
    return () => {
      setSElectedOptions(options);
    };
  }

  const handleSearch = useCallback(
    (e) => {
      let time;
      const { value } = e.target;
      setSelectedLabel(value);

      // eslint-disable-next-line consistent-return
      function debounce() {
        clearTimeout(time);
        if (!time) {
          time = setTimeout(() => {
            setSElectedOptions((prev) => {
              if (!value) return options;
              const sarched = prev.filter((item) =>
                item.label?.toLowerCase().match(value?.toLowerCase())
              );
              return sarched?.length ? sarched : options;
            });
          }, 500);
          return time;
        }
      }
      return debounce();
    },
    [setSelectedLabel, setSElectedOptions, options]
  );

  const handleSelect = useCallback(
    (option) => (e) => {
      e.preventDefault();
      const { label } = option;
      onSelect(option);
      setSelectedLabel((prev) => (prev !== label ? label : prev));
    },
    [onSelect, setSelectedLabel]
  );

  return {
    handleSearch,
    handleSelect,
    SearchEffect,
  };
}
