const users = {
  title: "Configurações",
  subtitle: "Usuários",
  newUser: "Incluir usuário",
  table: {
    head: {
      name: "Nome",
      email: "E-mail",
      role: "Função",
    },
  },
};

export default users;
