import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

// mixins

const scaleMixin = (prop) => {
  const sizes = {
    small: css`
      padding: 0.44rem 1.2rem;
      font-size: 1.4rem;
    `,
    normal: css`
      padding: 0.8rem 1.8rem;
      font-size: 1.6rem;
    `,
    big: css`
      padding: 1.2rem 2.4rem;
      font-size: 1.8rem;
    `,
  };

  return sizes[prop];
};

const kindMixin = (theme, prop) => {
  const colors = {
    primary: `${theme?.primary_2 || "#118ACA"};`,
    cancel: `${theme?.error || "#D0021B"};`,
    disabled: `${theme?.grey_4 || "#DBDBDB"};`,
  };

  return colors[prop];
};

const svgScale = (prop) => {
  const size = {
    small: "16px",
    normal: "18px",
    big: "24px",
  };
  return size[prop];
};

// element
// eslint-disable-next-line import/prefer-default-export
export const LINK = styled(Link)`
  ${({ theme, kind, scale, isDisabled }) => `
      isoutline: none;
      text-decoration: none;
      font-family: 'Whitney A', 'Whitney B', 'Opens Sans', sans-serif;
      background: ${
        isDisabled ? theme.colors.grey_4 : kindMixin(theme.colors, kind)
      };
      color: ${"#fff"};
      border: none;
      border-radius: 4px;
      overflow: auto;
      text-align: center;
      display: inline-flex;
      align-items: center;
      transition: all 0.5s ease;
      opacity: 1;
      &:focus,
      &:active,
      &:hover{
        opacity: 0.8;
      }
      > svg {
        margin: auto 4px auto 0;
        width: ${svgScale(scale)};
        height: ${svgScale(scale)};
        fill: #fff;
      }
      ${scaleMixin(scale)};

      &.isDisabled{
        background: ${kindMixin(theme.colors, "disabled")}
      }

      &.isOutline{
        background: transparent;
        color: ${kindMixin(theme.colors, kind)};
        border: 1px solid;
        border-color: ${kindMixin(theme.colors, kind)};
        &.isDisabled{
          border-color: ${kindMixin(theme.colors, "disabled")};
          color: ${kindMixin(theme.colors, "disabled")};
        }
        & > svg {
          fill: ${kindMixin(theme.colors, kind)};
        }
      }

    `}
`;
