const deployments = {
  title: "Deployments",
  newDeployment: "Nuevo Deployment",
  viewAll: "Ver todos",
  noAccounts: {
    title: "Oye",
    text: "¿Le gustaría crear su cuenta?",
    actionTxt: "Crear cuenta",
  },
  noDeployments: {
    title: "Aún no tienes deployment.",
    text: "Haz clic en Crea tu deployment para agregar la primera.",
    actionTxt: "Crea tu deployment",
  },
  deployments: {
    accordion: {
      tab_1: {
        table: {
          head: {},
          body: {
            col_1: {
              id: "id",
              slug: "nombre",
              status: "estado",
              type: "tipo",
            },
            col_2: {
              provisioned: "Aprovisionado",
              provisioning: "Aprovisionando",
              deleting: "Eliminando",
            },
          },
        },
        status: {
          provisioned: "Aprovisionado",
          provisioning: "Aprovisionando",
          deleting: "Eliminando",
        },
      },
      tab_2: {
        name: "Servicios",
        table: {
          head: {
            service: "Servicio",
            link: "Enlace",
          },
          body: {
            linkTxt: "Ir a {{ serviceType }}",
          },
        },
      },
    },
    actions: {
      delete: "Borrar",
      edit: "Editar",
    },
    alerts: {
      deleting: {
        ask: {
          title: "Borrar deployment",
          text:
            "<p>¿Realmente quieres eliminar esto recurso?</p><p>Escriba <b>{{ serviceName }}</b> para borrarlo, o haga clic en cancelar.</p>",
          buttons: {
            confirm: "Continuar",
            cancel: "Cancelar",
          },
        },
        success: {
          msg: "El recurso se eliminó correctamente.",
        },
      },
      errorGet:
        "No fue posible cargar todas los deployment de esta cuenta. Inténtalo de nuevo, o llámanos.",
    },
  },
};

export default deployments;
