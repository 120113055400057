const users = {
  title: "Ajustes",
  subtitle: "Usuarios",
  newUser: "Agregar usuario",
  table: {
    head: {
      name: "Nombre",
      email: "E-mail",
      role: "Responsabilidad",
    },
  },
};

export default users;
