import styled from "styled-components";
import { Loading } from "semantix-design-system/components";

const commonRules = `
display: grid;
place-items: center;
`;

export const LoaderWrap = styled.div`
  ${({ full }) => {
    if (full) {
      return `
            width: 100vw;
            min-height: 100vh;
            position: fixed;
            top: 0;
            left: 0;
            z-index:999;
            ${commonRules};
            background-color: #fff;
        `;
    }

    return `
        width: 100%;
        position: relative;
        margin: auto;
        ${commonRules}
    `;
  }}
`;

export const LOADING = styled(Loading)``;
