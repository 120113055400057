const newAccount = {
  backLink: "Back",
  title: "New account",
  continueLink: "Continue",
  form: {
    fields: {
      labels: {
        account: "Account",
      },
      errors: {
        required: "{{ field }} is required.",
        minLen: "At least {{ len }} characters.",
      },
    },
    buttons: {
      submit: "ok",
    },
  },
};

export default newAccount;
