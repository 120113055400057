import styled from "styled-components";

import { Box, Title } from "semantix-design-system/components";

export const CardContent = styled.div`
  margin-top: 16px;
  line-height: 1.5em;
`;

export const BOX = styled(Box)`
  ${({ theme }) => theme.xs`
      border-color: ${theme.colors?.primary_2};
      padding: 2.1rem 2.8rem 3.6rem 2.1rem;
      font-family: 'Whitney', 'Opens Sans', sans-serif;
  `}
`;

export const TITLE = styled(Title)`
  ${({ theme }) => theme.xs`
    color: ${theme.colors?.primary_2};
    font-size: 2.4rem;
    font-weight: 500;
  `}
`;
