/* eslint-disable max-len */
import styled from "styled-components";
import { Icon, Paragraph } from "semantix-design-system/components";

const transition = "transition: all 0.5s ease;";
export const ICON = styled(Icon)`
  ${({ theme }) => `
    width: 24px;
    height: 24px;
    fill: ${theme.colors?.grey_2};
    ${transition};
  `}
`;
export const PARAGRAPH = styled(Paragraph)`
  ${({ theme }) => theme.xs`
    font-size: 1.6rem;
    font-weight: 500;
    ${transition};
  `};
`;
export const Wrap = styled.div`
  position: relative;
  width: 100%;
  height: auto;
`;
export const LabelHeader = styled.div`
  width: inherit;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 12px;
`;
export const LabelTitle = styled.div`
  ${({ theme }) => `
    font-size: 1.8rem;
    font-weight: 700;
    color: ${theme.colors?.grey_2};
    margin-left: 32px;
    ${transition};
  `}
`;
export const LabelContent = styled.div``;
export const Svg = styled.svg`
  ${({ theme }) => theme.xs`
    width: 32px;
    height: 32px;
    position: absolute;
    border: 1px solid  ${theme.colors?.grey_4};
    border-radius: 100px;
    right: -8px;
    top: -8px;
    z-index: 10;
    background: ${theme.colors?.white};
    ${transition};

    ${() => theme.md`

    `}
  `}
`;
export const Path = styled.path`
  ${({ theme }) => `
      fill: ${theme.colors?.grey_4};
      d: path("M 7.571 4.1 L 7.571 7.6 L 4.1 7.6 C 4.045 7.6 4 7.645 4 7.7 L 4 8 C 4 8.055 4.045 8.1 4.1 8.1 L 7.571 8.1 L 7.571 11.9 C 7.571 11.955 7.616 12 7.671 12 L 8.043 12 C 8.098 12 8.143 11.955 8.143 11.9 L 8.143 8.1 L 11.9 8.1 C 11.955 8.1 12 8.055 12 8 L 12 7.7 C 12 7.645 11.955 7.6 11.9 7.6 L 8.143 7.6 L 8.143 4.1 C 8.143 4.045 8.098 4 8.043 4 L 7.671 4 C 7.616 4 7.571 4.045 7.571 4.1 Z");
      transform: rotate3d(0,0,0,180deg);

      transform-origin: center;
      transform-style: preserve-3d;
      transition: all 0.1s ease;

  `}
`;
export const Label = styled.label`
  ${({ theme }) => `
    position: inherit;
    width: inherit;
    height: 100%;
    display: block;
    padding: 1em 2em;
    border: 1px solid ${theme.colors?.grey_4};
    border-radius: 4px;
    ${transition};
    &:hover{
      cursor: pointer;
      border-color: ${theme.colors?.primary_3};
      ${LabelTitle},
      ${PARAGRAPH}{
        color: ${theme.colors?.primary_3};
      }
      ${Svg},
      ${ICON},
      ${Path}{
        border-color: ${theme.colors?.primary_3};
        fill: ${theme.colors?.primary_3};
      }
    }
  `}
`;
export const Input = styled.input`
  ${({ theme }) => theme.xs`
    position: absolute;
    &:checked + ${Label}{
      background: ${theme.colors?.primary_3};
        ${LabelTitle},
        ${PARAGRAPH}{
          color: ${theme.colors?.white};
        }

        ${ICON}{
          fill: ${theme.colors?.white};
        }

        ${Svg},
        ${Path}{
          border-color: ${theme.colors?.primary_3};
          fill: ${theme.colors?.success};
          transform: translate3d(0,0, 200px) rotate3d(0,1,0,180deg);
          d: path("M 11.487 4.746 L 5.713 10.519 L 4.484 9.347 C 4.437 9.302 4.361 9.303 4.314 9.349 L 4.034 9.63 C 3.987 9.676 3.989 9.75 4.037 9.794 L 5.626 11.257 C 5.674 11.301 5.751 11.3 5.798 11.254 L 11.965 5.224 C 12.011 5.178 12.012 5.103 11.966 5.057 L 11.654 4.746 C 11.608 4.699 11.533 4.699 11.487 4.746 Z");
        }
    }

    `}
`;
