import React from "react";
import PropTypes from "prop-types";
import * as S from "./authorizedHeader.style";

export default function AuthorizedHeader({ classNameHeader, ...props }) {
  return (
    <S.Header className={classNameHeader}>
      <S.CONTAINER>
        <S.ROW {...props} />
      </S.CONTAINER>
    </S.Header>
  );
}

AuthorizedHeader.defaultProps = {
  classNameHeader: "",
};
AuthorizedHeader.propTypes = {
  classNameHeader: PropTypes.string,
};
