const deployments = {
  title: "Deployments",
  newDeployment: "Novo Deployment",
  viewAll: "Ver todos",
  noAccounts: {
    title: "Olá",
    text: "Você gostaria de criar um ambiente para sua conta?",
    actionTxt: "Criar ambiente",
  },
  noDeployments: {
    title: "Você ainda não possui um deployment.",
    text: "Clique em 'Crie seu deployment' para criar para iniciar",
    actionTxt: "Crie seu deployment",
  },
  deployments: {
    accordion: {
      tab_1: {
        table: {
          head: {},
          body: {
            col_1: {
              id: "id",
              slug: "nome",
              status: "status",
              type: "tipo",
            },
            col_2: {
              provisioned: "Provisionado",
              provisioning: "Provisionando",
              deleting: "Excluindo",
            },
          },
        },
        status: {
          provisioning: "Provisionando",
          provisioned: "Provisionado",
          deleting: "Excluindo",
        },
      },
      tab_2: {
        name: "Serviços",
        table: {
          head: {
            service: "Serviço",
            link: "Link",
          },
          body: {
            linkTxt: "Ir para {{ serviceType }}",
          },
        },
      },
    },
    actions: {
      delete: "Deletar",
      edit: "Editar",
    },
    alerts: {
      deleting: {
        ask: {
          title: "Deletar deployment",
          text:
            "<p>Você gostaria de deletar este recurso?</p><p>Digite <b>{{ serviceName }}</b> para deletar, ou clique em cancelar.</p>",
          buttons: {
            confirm: "Continuar",
            cancel: "Cancelar",
          },
        },
        success: {
          msg: "O recurso {{ serviceName }} foi deletado com sucesso.",
        },
      },
      errorGet:
        "Não foi possivel carregar os deployments desta conta. Tente novamente, ou contate-nos.",
    },
  },
};

export default deployments;
