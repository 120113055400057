const createDeployment = {
  backButton: "Volver",
  title: "Crear Deployment",
  complement: {
    title: "Propósito del espacio",
    text:
      "¿Para qué serán sus entornos? Seleccione el tipo de uso del Espacio para que podamos brindarle los mejores Servicios y Aplicaciones.",
  },
  form: {
    submit: "Crear Deployment",
    fields: {
      errors: {
        required: "Selecciona un deployment.",
      },
    },
  },
};

export default createDeployment;
