const accounts = {
  title: "Settings",
  subtitle: "Accounts",
  newAccount: "New Account",
  accordeon: {
    tab_1: {},
    tab_2: {},
    actions: {
      delete: "Delete",
    },
    alerts: {
      delete: {
        ask: {
          title: "Deleting Account",
          text:
            "<p>Do you really want to delete the account <b>{{ accountName }}</b>?</p> <p>Every resource provisioned by that account will be deleted as well.</p>",
          buttons: {
            confirm: "Yes, continue",
            cancel: "No, cancel",
          },
        },
        success: {
          msg: "Account {{ accountName }} has been deleted successfully.",
        },
      },
    },
  },
};

export default accounts;
