/* eslint-disable import/prefer-default-export */
import { Api } from "Services";
import { getRoles, getRolesSuccess, getRolesFailure } from "../ducks/actions";

const { Registry } = new Api();

export const getRolesThunk = () => async (dispatch, getStore) => {
  const { Token: token } = await getStore();
  await dispatch(getRoles());
  return Registry(token?.data?.idToken)
    .get("/v1/profiles")
    .then(async ({ data }) => {
      const entries = await data?.entries;
      return dispatch(getRolesSuccess(entries));
    })
    .catch(async (err) => {
      const error = await err;
      return dispatch(getRolesFailure(error));
    });
};
