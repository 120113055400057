import { TriggerError } from "Utils";

const INITIAL_STATE = {
  loading: false,
  data: [],
  error: {},
};

const GET_USERS_REQUEST = "GET_USERS_REQUEST";
const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
const GET_USERS_FAILURE = "GET_USERS_FAILURE";

export const getUsers = () => ({
  type: GET_USERS_REQUEST,
  payload: null,
});

export const getUsersSuccess = (value) => ({
  type: GET_USERS_SUCCESS,
  payload: value,
});

export const getUsersFailure = (error) => {
  TriggerError(GET_USERS_FAILURE, error);
  return {
    type: GET_USERS_FAILURE,
    payload: { error },
  };
};

export default function Users(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_USERS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case GET_USERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return { ...state };
  }
}
