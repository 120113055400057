const accounts = {
  title: "Configurações",
  subtitle: "Contas",
  newAccount: "Nova conta",
  accordeon: {
    tab_1: {},
    actions: {
      delete: "Deletar",
    },
    alerts: {
      delete: {
        ask: {
          title: "Deletar Conta",
          text:
            "<p>Você deseja realmente remover a conta <b>{{ accountName }}</b> ?</p> <p>Todos os recursos gerados através desta conta também serão removidos.</p>",
          buttons: {
            confirm: "Sim, continuar",
            cancel: "Não, cancelar",
          },
        },
        success: {
          msg: "A conta {{ accountName }} foi removida com sucesso.",
        },
      },
    },
  },
};

export default accounts;
