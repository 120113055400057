import styled from "styled-components";
import { Container, Row } from "react-grid-system";

export const Header = styled.header`
  width: 100%;
  height: 72px;
  margin-bottom: 3.2rem;
`;

export const CONTAINER = styled(Container)`
  max-width: 1140px !important;
  height: inherit;
`;
export const ROW = styled(Row)`
  height: inherit;
`;
