import styled from "styled-components";
import EN from "Assets/img/flag_usa.svg";
import ES from "Assets/img/flag_spain.svg";
import PT from "Assets/img/flag_bra.svg";

const getFlag = (abbr) => {
  const flags = {
    en: EN,
    es: ES,
    pt: PT,
    "en-US": EN,
    "es-ES": ES,
    "pt-BR": PT,
  };

  return flags[abbr];
};

export const SelectorList = styled.ul`
  ${({ theme }) => theme.xs`
    position: absolute;
    width: 100%;
    list-style-type: none;
    background-color: ${theme.colors?.white};
    max-height: 0px;
    box-shadow: 0 2px 4px ${theme.colors?.grey_4};
    top: calc(100% + 4px);
    left: 0;
    z-index: 200;
    overflow: hidden;
    transition: 500ms ease;
  `}
`;
export const SelectorListItem = styled.li`
  margin-bottom: 16px;
`;
export const SelectorButton = styled.button`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  padding: 0;
  background: transparent;
`;

export const Flag = styled.div`
  ${({ theme, flag }) => theme.xs`
    width: 32px; 
    height: 32px;
    border: none;
    border-radius: 200px;
    background-image: url(${getFlag(flag)});
    background-repeat: no-repeat;
    background-size: cover;
    background-clip: border-box;
    margin: 0 auto;
  `}
`;
export const Selector = styled.div`
  position: relative;
  width: fill-available;
  height: auto;
  margin: 0 auto tr;
  z-index: 150;
  padding: 8px;
  &:hover,
  &:focus,
  &:focus-within {
    cursor: pointer;
    & > ${SelectorList} {
      padding: 8px 0 0 0;
      max-height: 200px;
    }
  }
`;
