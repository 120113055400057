/* eslint-disable react/display-name */
import React, { useCallback, lazy } from "react";
import { v4 as uuidv4 } from "uuid";

// unauthorized
const Home = lazy(() => import("Pages/Unauthorized/Home"));
const Signup = lazy(() => import("Pages/Unauthorized/Signup"));
// unauthorized auxiliarPage (Container less)
const Login = lazy(() => import("Pages/Unauthorized/Login"));
const Callback = lazy(() => import("Pages/Unauthorized/Callback"));
// autorized
const Users = lazy(() => import("Pages/Authorized/Users"));

const Deployments = lazy(() => import("Pages/Authorized/Deployments"));
const Settings = lazy(() => import("Pages/Authorized/Settings"));
const Accounts = lazy(() => import("Pages/Authorized/Accounts"));
const NewAccount = lazy(() => import("Pages/Authorized/NewAccount"));
const NewDeployment = lazy(() => import("Pages/Authorized/NewDeployment"));
const ManageUsers = lazy(() => import("Pages/Authorized/ManageUsers"));

export default function RoutesSchema() {
  const mapPathsCB = useCallback((route) => {
    const siblingPaths = route.siblings?.map((sibling) => sibling.path);
    const paths = route.path;

    if (siblingPaths) {
      return [...siblingPaths, paths];
    }
    return paths;
  }, []);

  // rotas não autorizadas renderizadas dentro do container Unauthorized
  const unauthorized = [
    {
      id: uuidv4(),
      name: "Home",
      path: "/home",
      i18Selector: "home",
      render: (props) => <Home {...props} />,
      exact: true,
    },
    {
      id: uuidv4(),
      name: "Register",
      path: "/register",
      i18Selector: "register",
      render: (props) => <Signup {...props} />,
      exact: true,
    },
  ];

  // rotas não autorizadas renderizadas fora do container
  const unauthorizedPages = [
    {
      id: uuidv4(),
      name: "Login",
      path: "/login",
      i18Selector: "login",
      render: (props) => <Login {...props} />,
      exact: true,
    },
    {
      id: uuidv4(),
      name: "Callback",
      path: "/callback",
      i18Selector: "callback",
      render: (props) => <Callback {...props} />,
      exact: true,
    },
  ];
  // rotas privadas com ancora (link) no menu de navegação
  const authorized = [
    {
      id: uuidv4(),
      name: "Deployments",
      path: "/deployments",
      icon: "Spaces",
      i18Selector: "deployments",
      render: (props) => <Deployments {...props} />,
      isPrivate: true,
      exact: true,
    },
    {
      id: uuidv4(),
      name: "Settings",
      path: "/settings",
      icon: "Settings",
      i18Selector: "settings",
      render: (props) => <Settings {...props} />,
      isPrivate: true,
      exact: true,
      siblings: [
        {
          id: uuidv4(),
          name: "Accounts",
          path: "/accounts",
          i18Selector: "accounts",
          render: (props) => <Accounts {...props} />,
          isPrivate: true,
        },
        {
          id: uuidv4(),
          name: "Users",
          path: "/users",
          i18Selector: "users",
          render: (props) => <Users {...props} />,
          isPrivate: true,
        },
      ],
    },
  ];
  // rotas privadas Renderizadas fora do container
  // const authorizedPages = [];

  // rotas privadas sem ancora (link) no menu de navegação
  const authorizedHidden = [
    {
      id: uuidv4(),
      name: "Add new user",
      path: "/manage-user",
      i18Selector: "newUser",
      render: (props) => <ManageUsers {...props} />,
      isPrivate: true,
    },
    {
      id: uuidv4(),
      name: "Create new account",
      path: "/manage-account",
      i18Selector: "newAccount",
      render: (props) => <NewAccount {...props} />,
      isPrivate: true,
    },
    {
      id: uuidv4(),
      name: "Create new deployment",
      path: "/manage-deployment",
      i18Selector: "newDeployment",
      render: (props) => <NewDeployment {...props} />,
      isPrivate: true,
    },
  ];

  const paths = {
    authorized: [
      ...authorized.map(mapPathsCB).flat(),
      ...authorizedHidden.map(mapPathsCB).flat(),
    ],
    unauthorized: [...unauthorized.map(mapPathsCB).flat()],
    unauthorizedPages: unauthorizedPages.map(mapPathsCB).flat(),
  };

  const removeRenderKey = (obj) => {
    const filteredKeys = Object.keys(obj).filter((i) => i !== "render");

    return filteredKeys.reduce((acc, curr) => {
      return {
        ...acc,
        [curr]: obj[curr],
      };
    }, {});
  };

  const navigationData = authorized.map((item) => {
    const reducedItem = removeRenderKey(item);
    if (item.siblings?.length) {
      return {
        ...reducedItem,
        siblings: item.siblings.map(removeRenderKey),
      };
    }

    return reducedItem;
  });

  return {
    paths,
    authorized,
    authorizedHidden,
    unauthorized,
    unauthorizedPages,
    navigationData,
  };
}
