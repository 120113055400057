const pLeft = `
    &.pl4{padding-left: 4px}
    &.pl8{padding-left: 8px}
    &.pl12{padding-left: 12px}
    &.pl16{padding-left: 16px}
    &.pl20{padding-left: 20px}
    &.pl24{padding-left: 24px}
    &.pl28{padding-left: 28px}
    &.pl32{padding-left: 32px}
    &.pl36{padding-left: 36px}
    &.pl40{padding-left: 40px}
    &.pl44{padding-left: 44px}
    &.pl48{padding-left: 48px}
  `;

const pRight = `
    &.pr4{padding-right: 4px}
    &.pr8{padding-right: 8px}
    &.pr12{padding-right: 12px}
    &.pr16{padding-right: 16px}
    &.pr20{padding-right: 20px}
    &.pr24{padding-right: 24px}
    &.pr28{padding-right: 28px}
    &.pr32{padding-right: 32px}
    &.pr36{padding-right: 36px}
    &.pr40{padding-right: 40px}
    &.pr44{padding-right: 44px}
    &.pr48{padding-right: 48px}
  `;

const pTop = `
    &.pTop4{padding-top: 4px}
    &.pTop8{padding-top: 8px}
    &.pTop12{padding-top: 12px}
    &.pTop16{padding-top: 16px}
    &.pTop20{padding-top: 20px}
    &.pTop24{padding-top: 24px}
    &.pTop28{padding-top: 28px}
    &.pTop32{padding-top: 32px}
    &.pTop36{padding-top: 36px}
    &.pTop40{padding-top: 40px}
    &.pTop44{padding-top: 44px}
    &.pTop48{padding-top: 48px}
  `;

const pBottom = `
    &.pBt4{padding-bottom: 4px}
    &.pBt8{padding-bottom: 8px}
    &.pBt12{padding-bottom: 12px}
    &.pBt16{padding-bottom: 16px}
    &.pBt20{padding-bottom: 20px}
    &.pBt24{padding-bottom: 24px}
    &.pBt28{padding-bottom: 28px}
    &.pBt32{padding-bottom: 32px}
    &.pBt36{padding-bottom: 36px}
    &.pBt40{padding-bottom: 40px}
    &.pBt44{padding-bottom: 44px}
    &.pBt48{padding-bottom: 48px}
  `;

const pH = `
    &.pH4{padding: 0 4px}
    &.pH8{padding: 0 8px}
    &.pH12{padding: 0 12px}
    &.pH16{padding: 0 16px}
    &.pH20{padding: 0 20px}
    &.pH24{padding: 0 24px}
    &.pH28{padding: 0 28px}
    &.pH32{padding: 0 32px}
    &.pH36{padding: 0 36px}
    &.pH40{padding: 0 40px}
    &.pH44{padding: 0 44px}
    &.pH48{padding: 0 48px}
  `;

const pV = `
    &.pV4{padding: 4px 0}
    &.pV8{padding: 8px 0}
    &.pV12{padding: 12px 0}
    &.pV16{padding: 16px 0}
    &.pV20{padding: 20px 0}
    &.pV24{padding: 24px 0}
    &.pV28{padding: 28px 0}
    &.pV32{padding: 32px 0}
    &.pV36{padding: 36px 0}
    &.pV40{padding: 40px 0}
    &.pV44{padding: 44px 0}
    &.pV48{padding: 48px 0}
  `;

export default function Margins() {
  return {
    pLeft,
    pRight,
    pTop,
    pBottom,
    pH,
    pV,
  };
}
