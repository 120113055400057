import React from "react";
import ReactDOM from "react-dom";
import { init as Sentry } from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { Provider } from "react-redux";

import { PersistGate } from "redux-persist/integration/react";

import { ThemeProvider } from "styled-components";
import Theme from "semantix-design-system/components/theme";
import "./i18n";
import App from "App";
import { Store, Persistor } from "Store";
import { GlobalStyle, Media } from "./Styles";

import * as serviceWorker from "./serviceWorker";

window.env = process?.env?.NODE_ENV;

Sentry({
  dsn: process.env.REACT_APP_SENTRY_URL,
  autoSessionTracking: true,
  environment: process.env.REACT_APP_ENV,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={Store}>
      <PersistGate loading={null} persistor={Persistor}>
        <ThemeProvider theme={{ ...Theme, ...Media }}>
          <App />
          <GlobalStyle />
        </ThemeProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.querySelector("#main")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

serviceWorker.register();
