const INITIAL_STATE = {
  loading: true,
  data: {},
};

const SET_SELECTED_ACCOUNT = "SET_SELECTED_ACCOUNT";
const SET_SELECTED_ACCOUNT_SUCCESS = "SET_SELECTED_ACCOUNT_SUCCESS";
const SET_SELECTED_ACCOUNT_FAILURE = "SET_SELECTED_ACCOUNT_FAILURE";

export const setSelectedAccount = () => ({
  type: SET_SELECTED_ACCOUNT,
});

export const setSelectedAccountSuccess = (value) => ({
  type: SET_SELECTED_ACCOUNT_SUCCESS,
  payload: value,
});

export const setSelectedAccountFailure = () => ({
  type: SET_SELECTED_ACCOUNT_FAILURE,
});

export default function SelectedAccount(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_SELECTED_ACCOUNT:
      return {
        ...state,
        loading: true,
      };
    case SET_SELECTED_ACCOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case SET_SELECTED_ACCOUNT_FAILURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return { ...state };
  }
}
