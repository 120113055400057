import { TriggerError } from "Utils";

const GET_ACCOUNTS_REQUEST = "GET_ACCOUNTS_REQUET";
const GET_ACCOUNTS_SUCCESS = "GET_ACCOUNTS_SUCCES";
const GET_ACCOUNTS_FAILURE = "GET_ACCOUNTS_FAILUE";

const INITIAL_STATE = {
  loading: false,
  data: [],
  error: {},
};

export const getAccounts = () => ({
  type: GET_ACCOUNTS_REQUEST,
  payload: null,
});

export const getAccountsSuccess = (value) => ({
  type: GET_ACCOUNTS_SUCCESS,
  payload: value,
});

export const getAccountsFailure = (error) => {
  TriggerError(GET_ACCOUNTS_FAILURE, error);
  return {
    type: GET_ACCOUNTS_FAILURE,
    payload: error,
  };
};

export default function Accounts(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_ACCOUNTS_REQUEST:
      return {
        loading: true,
        data: [...state.data],
        error: null,
      };
    case GET_ACCOUNTS_SUCCESS:
      return {
        loading: false,
        data: action.payload,
        error: null,
      };
    case GET_ACCOUNTS_FAILURE:
      return {
        loading: false,
        data: [...state.data],
        error: action.payload.error,
      };
    default:
      return { ...state };
  }
}
