/* eslint-disable react/forbid-prop-types */
import React, { createElement, useMemo } from "react";
import PropTypes from "prop-types";
import * as S from "./linkButton.style";

export default function LinkButton({
  to,
  kind,
  scale,
  outline,
  children,
  icon,
  className,
  disabled,
}) {
  const Icon = useMemo(() => !!icon && createElement(icon), [icon]);
  return (
    <S.LINK
      to={disabled ? "#" : to}
      kind={kind || null}
      scale={scale || null}
      disabled={disabled}
      className={`${className} ${outline ? "isOutline" : ""} ${
        disabled ? "isDisabled" : ""
      }`}
    >
      {Icon}
      {children}
    </S.LINK>
  );
}

LinkButton.propTypes = {
  to: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      pathname: PropTypes.string,
      hash: PropTypes.string,
      query: PropTypes.string,
      state: PropTypes.any,
    }),
  ]).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.node,
    PropTypes.func,
  ]),
  kind: PropTypes.string,
  outline: PropTypes.bool,
  disabled: PropTypes.bool,
  icon: PropTypes.any,
  scale: PropTypes.string,
  className: PropTypes.string,
};

LinkButton.defaultProps = {
  kind: "primary",
  outline: false,
  disabled: false,
  scale: "normal",
  icon: undefined,
  className: "",
  children: null,
};
