/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from "react";
import LangSelectorViewModel from "./LanguageSelector";
import * as S from "./LanguageSelector.style";

export default function LangSelector() {
  const vm = LangSelectorViewModel();

  const renderLangOptions = useMemo(() => {
    const lenguages = vm.languages.filter((lang) => lang !== vm.selectedLang);

    return lenguages.map((lang) => (
      <S.SelectorListItem key={lang}>
        <S.SelectorButton onClick={vm.setLang(lang)}>
          <S.Flag flag={lang} />
        </S.SelectorButton>
      </S.SelectorListItem>
    ));
  }, [vm.selectedLang, vm.languages]);

  return (
    <S.Selector>
      <S.SelectorButton>
        <S.Flag flag={vm.selectedLang} />
      </S.SelectorButton>
      <S.SelectorList>{renderLangOptions}</S.SelectorList>
    </S.Selector>
  );
}
