import styled, { css } from "styled-components";
import { Container, Row, Col } from "react-grid-system";
import { Link } from "react-router-dom";
import { Modifiers } from "Styles";
import OGLogo from "Assets/img/Logo_Open_Galaxy.png";

const logoRules = css`
  &.logo {
    width: min(100vw, 112px);
    height: auto;
    position: relative;
    display: block;
    ${({ theme }) => theme.md`
      width: min(100vw, 200px);
    `}
  }
`;

export const Header = styled.header`
  width: 100vw;
  height: 72px;
`;
export const LogoImg = styled.img.attrs({
  src: OGLogo,
  alt: "anchor to Home",
  className: "logo",
})`
  ${logoRules}
`;
export const CONTAINER = styled(Container)`
  position: relative;
  height: 100%;
  max-width: 1140px !important;
`;
export const ROW = styled(Row)`
  position: relative;
  height: 100%;
`;
export const COL = styled(Col)`
  position: relative;
  height: 100%;
  ${Modifiers.flex}
  ${Modifiers.flow}
  ${Modifiers.flexAlign}
  ${Modifiers.flexJustify}
  ${Modifiers.pTop}
  ${Modifiers.pBottom}
`;
export const LINK = styled(Link)`
  ${({ theme }) => `
    color: ${theme.colors?.primary_2};
    width: min(50vw, 150px);
    cursor: pointer;
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 1.2em;
    display: block;
    text-decoration: none;
    transition: all 0.2s ease-in-out;
    &:hover{
      color${theme.colors?.primary_3};
    }
    &.isInvisible{
      visibility: hidden;
    }
`}
`;
