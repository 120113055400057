import React from "react";

import * as S from "./unauthorizedHeader.style";

export default function UnauthorizedHEader() {
  return (
    <S.Header>
      <S.CONTAINER>
        <S.ROW xs={12}>
          <S.COL className="flex flowNRW alignC justSB">
            <S.LINK to="/">
              {" "}
              <S.LogoImg />{" "}
            </S.LINK>
          </S.COL>
        </S.ROW>
      </S.CONTAINER>
    </S.Header>
  );
}
