import { Api } from "Services";
import { Toast, Modal, modalWarnConfig } from "Utils";
import {
  getAccounts,
  getAccountsSuccess,
  getAccountsFailure,
} from "../ducks/actions";

import { setSelectedAccountThunk } from "./selectedAccount";

const { Registry } = new Api();

export const getAccountsThunk = () => async (dispatch, getStore) => {
  const { Token: token } = await getStore();

  dispatch(getAccounts());
  return Registry(token.data.idToken)
    .get("/v1/accounts")
    .then(async ({ data }) => {
      const entries = await data?.entries;
      await dispatch(getAccountsSuccess(entries));
      const { SelectedAccount } = await getStore();

      if (!Object.keys(SelectedAccount?.data).length) {
        if (entries?.length) {
          const [first] = await entries;
          const firstSelect = await {
            value: first.id,
            label: first.name,
          };
          return dispatch(setSelectedAccountThunk(firstSelect));
        }
        return dispatch(setSelectedAccountThunk());
      }
      return dispatch(setSelectedAccountThunk());
    })
    .catch(async (error) => { 
      await dispatch(getAccountsFailure(error));
      await dispatch(setSelectedAccountThunk());
      return null;
    });
};

export const postNewAccount = (values, { resetForm, setSubmitting }) => (
  dispatch,
  getStore
) => {
  const { Token: token } = getStore();

  const postAccountSuccess = async ({ data }) => {
    await Toast.fire({
      icon: "success",
      title: "Account added successfully!",
    });
    await dispatch(
      setSelectedAccountThunk({
        value: data?.id,
        label: data?.name,
      })
    );
    await resetForm();
    await setSubmitting(false);
    return dispatch(getAccountsThunk());
  };

  const postAccountError = async (err) => {
    const { response, message } = await err;

    if (response?.data?.message) {
      await Toast.fire({
        icon: "error",
        title: response.data?.message,
      });

      return setSubmitting(false);
    }

    if (message && response?.status !== 201) {
      await Toast.fire({
        icon: "error",
        title: message,
      });

      return setSubmitting(false);
    }
    return setSubmitting(false);
  };

  Registry(token?.data?.idToken)
    .post("/v1/accounts", { ...values })
    .then(postAccountSuccess)
    .catch(postAccountError);
};

export const removeAccount = ({ account, alerts }) => async (dispatch) => {
  const modalAction = await Modal.fire({
    ...modalWarnConfig,
    icon: "warning",
    title: alerts.delete.ask.title,
    html: alerts.delete.ask.text,
    confirmButtonText: alerts.delete.ask.buttons.confirm,
    cancelButtonText: alerts.delete.ask.buttons.cancel,
  });

  if (modalAction.value) {
    return Toast.fire({
      icon: "success",
      title: alerts.delete.success.msg,
    });
  }

  return { account, dispatch };
};
