/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import AuthorizedContainerViewModel from "./authorized";
import * as S from "./autorized.style";

export default function Authorized({ children, navSchema }) {
  const vm = AuthorizedContainerViewModel();

  useEffect(vm.AuthContainerEffect, [vm.location]);

  return (
    <S.Section>
      <S.NAVIGATION options={navSchema} />
      <S.Div>
        <S.HEADER align="center" justify="end">
          <S.COL xs={3} sm={2} lg={1}>
            <S.LANGSELECTOR />
          </S.COL>
        </S.HEADER>

        {children}
      </S.Div>
    </S.Section>
  );
}

Authorized.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.element]).isRequired,
  navSchema: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      path: PropTypes.string,
      icon: PropTypes.string,
      i18Selector: PropTypes.string,
      exact: PropTypes.bool,
      siblings: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          path: PropTypes.string,
          i18Selector: PropTypes.string,
          exact: PropTypes.bool,
        })
      ),
    })
  ).isRequired,
};
