const heightVP = `
    &.vh1: { height: '1vh' }
    &.vh2: { height: '2vh' }
    &.vh3: { height: '3vh' }
    &.vh4: { height: '4vh' }
    &.vh5: { height: '5vh' }
    &.vh6: { height: '6vh' }
    &.vh7: { height: '7vh' }
    &.vh8: { height: '8vh' }
    &.vh9: { height: '9vh' }
    &.vh10: { height: '10vh' }
    &.vh11: { height: '11vh' }
    &.vh12: { height: '12vh' }
    &.vh13: { height: '13vh' }
    &.vh14: { height: '14vh' }
    &.vh15: { height: '15vh' }
    &.vh16: { height: '16vh' }
    &.vh17: { height: '17vh' }
    &.vh18: { height: '18vh' }
    &.vh19: { height: '19vh' }
    &.vh20: { height: '20vh' }
    &.vh21: { height: '21vh' }
    &.vh22: { height: '22vh' }
    &.vh23: { height: '23vh' }
    &.vh24: { height: '24vh' }
    &.vh25: { height: '25vh' }
    &.vh26: { height: '26vh' }
    &.vh27: { height: '27vh' }
    &.vh28: { height: '28vh' }
    &.vh29: { height: '29vh' }
    &.vh30: { height: '30vh' }
    &.vh31: { height: '31vh' }
    &.vh32: { height: '32vh' }
    &.vh33: { height: '33vh' }
    &.vh34: { height: '34vh' }
    &.vh35: { height: '35vh' }
    &.vh36: { height: '36vh' }
    &.vh37: { height: '37vh' }
    &.vh38: { height: '38vh' }
    &.vh39: { height: '39vh' }
    &.vh40: { height: '40vh' }
    &.vh41: { height: '41vh' }
    &.vh42: { height: '42vh' }
    &.vh43: { height: '43vh' }
    &.vh44: { height: '44vh' }
    &.vh45: { height: '45vh' }
    &.vh46: { height: '46vh' }
    &.vh47: { height: '47vh' }
    &.vh48: { height: '48vh' }
    &.vh49: { height: '49vh' }
    &.vh50: { height: '50vh' }
    &.vh51: { height: '51vh' }
    &.vh52: { height: '52vh' }
    &.vh53: { height: '53vh' }
    &.vh54: { height: '54vh' }
    &.vh55: { height: '55vh' }
    &.vh56: { height: '56vh' }
    &.vh57: { height: '57vh' }
    &.vh58: { height: '58vh' }
    &.vh59: { height: '59vh' }
    &.vh60: { height: '60vh' }
    &.vh61: { height: '61vh' }
    &.vh62: { height: '62vh' }
    &.vh63: { height: '63vh' }
    &.vh64: { height: '64vh' }
    &.vh65: { height: '65vh' }
    &.vh66: { height: '66vh' }
    &.vh67: { height: '67vh' }
    &.vh68: { height: '68vh' }
    &.vh69: { height: '69vh' }
    &.vh70: { height: '70vh' }
    &.vh71: { height: '71vh' }
    &.vh72: { height: '72vh' }
    &.vh73: { height: '73vh' }
    &.vh74: { height: '74vh' }
    &.vh75: { height: '75vh' }
    &.vh76: { height: '76vh' }
    &.vh77: { height: '77vh' }
    &.vh78: { height: '78vh' }
    &.vh79: { height: '79vh' }
    &.vh80: { height: '80vh' }
    &.vh81: { height: '81vh' }
    &.vh82: { height: '82vh' }
    &.vh83: { height: '83vh' }
    &.vh84: { height: '84vh' }
    &.vh85: { height: '85vh' }
    &.vh86: { height: '86vh' }
    &.vh87: { height: '87vh' }
    &.vh88: { height: '88vh' }
    &.vh89: { height: '89vh' }
    &.vh90: { height: '90vh' }
    &.vh91: { height: '91vh' }
    &.vh92: { height: '92vh' }
    &.vh93: { height: '93vh' }
    &.vh94: { height: '94vh' }
    &.vh95: { height: '95vh' }
    &.vh96: { height: '96vh' }
    &.vh97: { height: '97vh' }
    &.vh98: { height: '98vh' }
    &.vh99: { height: '99vh' }
    &.vh100: { height: '100vh' }`;

const heightPC = `
    &.pct1: { height: '1%' }
    &.pct2: { height: '2%' }
    &.pct3: { height: '3%' }
    &.pct4: { height: '4%' }
    &.pct5: { height: '5%' }
    &.pct6: { height: '6%' }
    &.pct7: { height: '7%' }
    &.pct8: { height: '8%' }
    &.pct9: { height: '9%' }
    &.pct10: { height: '10%' }
    &.pct11: { height: '11%' }
    &.pct12: { height: '12%' }
    &.pct13: { height: '13%' }
    &.pct14: { height: '14%' }
    &.pct15: { height: '15%' }
    &.pct16: { height: '16%' }
    &.pct17: { height: '17%' }
    &.pct18: { height: '18%' }
    &.pct19: { height: '19%' }
    &.pct20: { height: '20%' }
    &.pct21: { height: '21%' }
    &.pct22: { height: '22%' }
    &.pct23: { height: '23%' }
    &.pct24: { height: '24%' }
    &.pct25: { height: '25%' }
    &.pct26: { height: '26%' }
    &.pct27: { height: '27%' }
    &.pct28: { height: '28%' }
    &.pct29: { height: '29%' }
    &.pct30: { height: '30%' }
    &.pct31: { height: '31%' }
    &.pct32: { height: '32%' }
    &.pct33: { height: '33%' }
    &.pct34: { height: '34%' }
    &.pct35: { height: '35%' }
    &.pct36: { height: '36%' }
    &.pct37: { height: '37%' }
    &.pct38: { height: '38%' }
    &.pct39: { height: '39%' }
    &.pct40: { height: '40%' }
    &.pct41: { height: '41%' }
    &.pct42: { height: '42%' }
    &.pct43: { height: '43%' }
    &.pct44: { height: '44%' }
    &.pct45: { height: '45%' }
    &.pct46: { height: '46%' }
    &.pct47: { height: '47%' }
    &.pct48: { height: '48%' }
    &.pct49: { height: '49%' }
    &.pct50: { height: '50%' }
    &.pct51: { height: '51%' }
    &.pct52: { height: '52%' }
    &.pct53: { height: '53%' }
    &.pct54: { height: '54%' }
    &.pct55: { height: '55%' }
    &.pct56: { height: '56%' }
    &.pct57: { height: '57%' }
    &.pct58: { height: '58%' }
    &.pct59: { height: '59%' }
    &.pct60: { height: '60%' }
    &.pct61: { height: '61%' }
    &.pct62: { height: '62%' }
    &.pct63: { height: '63%' }
    &.pct64: { height: '64%' }
    &.pct65: { height: '65%' }
    &.pct66: { height: '66%' }
    &.pct67: { height: '67%' }
    &.pct68: { height: '68%' }
    &.pct69: { height: '69%' }
    &.pct70: { height: '70%' }
    &.pct71: { height: '71%' }
    &.pct72: { height: '72%' }
    &.pct73: { height: '73%' }
    &.pct74: { height: '74%' }
    &.pct75: { height: '75%' }
    &.pct76: { height: '76%' }
    &.pct77: { height: '77%' }
    &.pct78: { height: '78%' }
    &.pct79: { height: '79%' }
    &.pct80: { height: '80%' }
    &.pct81: { height: '81%' }
    &.pct82: { height: '82%' }
    &.pct83: { height: '83%' }
    &.pct84: { height: '84%' }
    &.pct85: { height: '85%' }
    &.pct86: { height: '86%' }
    &.pct87: { height: '87%' }
    &.pct88: { height: '88%' }
    &.pct89: { height: '89%' }
    &.pct90: { height: '90%' }
    &.pct91: { height: '91%' }
    &.pct92: { height: '92%' }
    &.pct93: { height: '93%' }
    &.pct94: { height: '94%' }
    &.pct95: { height: '95%' }
    &.pct96: { height: '96%' }
    &.pct97: { height: '97%' }
    &.pct98: { height: '98%' }
    &.pct99: { height: '99%' }
    &.pct100: { height: '100%' }`;

export default function relativeHeights() {
  return {
    heightPC,
    heightVP,
  };
}
