import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { setSelectedAccountThunk } from "Store/Thunks";

export default function AccountsSelectorViewModel({ useMemo }) {
  const { t, i18n } = useTranslation("accountSelector");
  const dispatch = useDispatch();
  const { SelectedAccount, Accounts } = useSelector((s) => s);

  const options = useMemo(
    () =>
      Accounts?.data?.map((account) => ({
        label: account.name,
        value: account.id,
      })),
    [Accounts]
  );

  const handleSelect = (account) => {
    return dispatch(setSelectedAccountThunk(account));
  };
  this.selectedLang = i18n?.language;
  this.t = t;
  this.options = options;
  this.handleSelect = handleSelect;
  this.accounts = Accounts;
  this.account = SelectedAccount;
}
