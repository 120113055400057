/* eslint-disable prefer-const */
import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { ErrorBoundary as SentryError } from "@sentry/react";

export default function ErrorBoundary({ children }) {
  const fallbackMessage = useCallback(
    ({ error, componentStack, resetError }) => (
      <>
        <div>You have encountered an error</div>
        <div>{error.toString()}</div>
        <div>{componentStack}</div>
        <button
          type="button"
          onClick={() => {
            resetError();
          }}
        >
          Click here to reset!
        </button>
      </>
    ),
    []
  );
  return (
    <SentryError fallback={fallbackMessage} showDialog>
      {children}
    </SentryError>
  );
}

ErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.node,
    PropTypes.func,
  ]).isRequired,
};
