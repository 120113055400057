/* eslint-disable import/prefer-default-export */
import { Api } from "Services";
import {
  getTemplates,
  getTemplatesSuccess,
  getTemplatesFailure,
} from "../ducks/actions";

const { Inventory } = new Api();

export function getTemplatesThunk() {
  return (dispatch, getStore) => {
    const { Token: token } = getStore();
    dispatch(getTemplates());
    return Inventory(token?.data?.idToken)
      .get("/v1/deployment_templates")
      .then(({ data }) => dispatch(getTemplatesSuccess(data?.entries)))
      .catch((err) => dispatch(getTemplatesFailure(err)));
  };
}
