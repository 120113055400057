const users = {
  title: "Settings",
  subtitle: "Users",
  newUser: "Add user",
  table: {
    head: {
      name: "Name",
      email: "Mail",
      role: "Role",
    },
  },
};

export default users;
