const newAccount = {
  backLink: "Volver",
  title: "Nueva cuenta",
  continueLink: "Seguir",
  form: {
    fields: {
      labels: {
        account: "cuenta",
      },
      errors: {
        required: "{{ field }} es obligato.",
        minLen: "Al menos {{ len }} caracteres",
      },
    },
    buttons: {
      submit: "ok",
    },
  },
};

export default newAccount;
