import axios from "axios";

export default function API() {
  const create = (baseURL, token) => {
    const headers = {
      "Content-Type": "Application/json",
      Authorization: `bearer ${token}`,
    };

    return axios.create({ baseURL, headers });
  };

  this.Registry = (token) => {
    return create(process.env.REACT_APP_API_REGISTRY, token);
  };

  this.Inventory = (token) =>
    create(process.env.REACT_APP_API_INVENTORY, token);
}
