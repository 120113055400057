const navigation = {
  deployments: "Deployments",
  logout: "Sair",
  settings: "Configurações",
  siblings: {
    accounts: "Conta",
    users: "Usuários",
  },
};

export default navigation;
