import React from "react";
import PropTypes from "prop-types";
import * as S from "./loader.style";

const Loader = ({ full, className }) => (
  <S.LoaderWrap full={full} className={className}>
    <S.LOADING />
  </S.LoaderWrap>
);

Loader.defaultProps = { full: false, className: "" };
Loader.propTypes = { full: PropTypes.bool, className: PropTypes.string };

export default Loader;
