export default function AccordionTabViewModel({ setActived, setChild }) {
  this.tabAction = ({ id, child }) => () => {
    setChild((prev) => {
      if (prev === child) {
        setActived(null);
        return null;
      }

      setActived(id);
      return child;
    });
  };
}
