import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { logout } from "Services";
import { ClearStore } from "Store";
import NavigationData from "./navigation.data.json";

export default function NavigationViewModel({ props, setSubMenu, setIsOpen }) {
  const { t } = useTranslation("navigation");
  const { options } = props;

  const { bottomWhiteList } = NavigationData;

  const bottomOptions = options.filter((option) =>
    bottomWhiteList.includes(option.name)
  );

  const contentOptions = options.filter(
    (option) => !bottomWhiteList.includes(option.name)
  );

  const OpenSubMenu = (data) => (e) => {
    e.preventDefault();
    setIsOpen((prev) => !prev);
    setSubMenu(data);
  };

  const Logout = async () => {
    await ClearStore();
    await logout();
  };

  return {
    t,
    bottomOptions,
    contentOptions,
    OpenSubMenu,
    Logout,
  };
}

NavigationViewModel.propTypes = {
  setIsOpen: PropTypes.func,
  setSubMenu: PropTypes.func,
  props: PropTypes.shape({
    options: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        path: PropTypes.string,
        icon: PropTypes.string,
        i18Selector: PropTypes.string,
        exact: PropTypes.bool,
        siblings: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string,
            path: PropTypes.string,
            i18Selector: PropTypes.string,
            exact: PropTypes.bool,
          })
        ),
      })
    ).isRequired,
    subMenuTitle: PropTypes.string,
  }),
};
