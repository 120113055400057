import { TriggerError } from "Utils";

const INITIAL_STATE = {
  loading: false,
  data: [],
  error: {},
};

const GET_TEMPLATES_REQUEST = "GET_TEMPLATES_REQUEST";
const GET_TEMPLATES_SUCCESS = "GET_TEMPLATES_SUCCESS";
const GET_TEMPLATES_FAILURE = "GET_TEMPLATES_FAILURE";

export const getTemplates = () => ({
  type: GET_TEMPLATES_REQUEST,
  payload: null,
});

export const getTemplatesSuccess = (value) => ({
  type: GET_TEMPLATES_SUCCESS,
  payload: value,
});

export const getTemplatesFailure = (error) => {
  TriggerError(GET_TEMPLATES_FAILURE, error);
  return {
    type: GET_TEMPLATES_FAILURE,
    payload: { error },
  };
};

export default function Templates(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_TEMPLATES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_TEMPLATES_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case GET_TEMPLATES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return {
        ...state,
      };
  }
}
