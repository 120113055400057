const INITIAL_STATE = {
  data: {},
};

const GET_TOKEN = "GET_TOKEN";
const RESET_TOKEN = "RESET_TOKEN";

export const getToken = (value) => ({
  type: GET_TOKEN,
  payload: value,
});

export const resetToken = () => ({ type: RESET_TOKEN, payload: null });

export default function Token(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_TOKEN:
      return {
        data: action.payload,
      };
    case RESET_TOKEN:
      return { ...state };
    default:
      return { ...state };
  }
}
