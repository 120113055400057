import React from "react";
import PropTypes from "prop-types";
import * as S from "./ellipsisLoader.style";

export default function EllipsisLoader({ className }) {
  return (
    <S.Loader className={className}>
      <S.Div />
      <S.Div />
      <S.Div />
      <S.Div />
    </S.Loader>
  );
}

EllipsisLoader.defaultProps = {
  className: "",
};
EllipsisLoader.propTypes = { className: PropTypes.string };
