import React, { Suspense, Fragment, useCallback } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { Authorized } from "Containers";
import RoutesSchema from "./routes";
import * as S from "./routes.style";

export default function OepnGalaxyRoutes() {
  const schema = RoutesSchema();

  const mapRoute = useCallback(
    (route) => (
      <Fragment key={route.id}>
        <Route path={route.path} render={route.render} exact />
        {route.siblings?.map(mapRoute)}
      </Fragment>
    ),
    []
  );

  return (
    <Suspense fallback={<S.LOADER full />}>
      <Switch>
        <Route path="/" exact>
          <Redirect to="/login" />
        </Route>

        <Route path={schema.paths.unauthorizedPages} exact>
          <Suspense fallback={<S.LOADER full />}>
            {schema.unauthorizedPages.map(mapRoute)}
          </Suspense>
        </Route>

        {/* <Route path={schema.paths.unauthorized} exact>
          <Unauthorized>
            <Suspense fallback={<S.LOADER full />}>
              {schema.unauthorized.map(mapRoute)}
            </Suspense>
          </Unauthorized>
        </Route> */}

        <Route path={schema.paths.authorized} exact>
          <Authorized navSchema={schema.navigationData}>
            <Suspense fallback={<S.LOADER full />}>
              {schema.authorized.map(mapRoute)}
              {schema.authorizedHidden.map(mapRoute)}
            </Suspense>
          </Authorized>
        </Route>

        <Route path="*" render={() => <div>404</div>} />
      </Switch>
    </Suspense>
  );
}
