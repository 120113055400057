export default function TextAlign() {
  const textAlign = `
    &.txtR{text-align:right}
    &.txtC{text-align:center}
    &.txtL{text-align:left}
  `;
  return {
    textAlign,
  };
}
