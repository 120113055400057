const createDeployment = {
  backButton: "Voltar",
  title: "Criar Deployment",
  complement: {
    title: "Propósito do espaço",
    text:
      "Para quais propósitos serão seus ambientes? Selecione o tipo de uso do Espaço para que possamos fornecer os melhores Serviços e Aplicativos.",
  },
  form: {
    submit: "Cria Deployment",
    fields: {
      errors: {
        required: "Selecione um deployment.",
      },
    },
  },
};

export default createDeployment;
