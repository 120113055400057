import styled from "styled-components";
import { Link } from "react-router-dom";
import MenuBG from "Assets/img/opengalaxy-background.png";
import OpenGalaxyLogo from "Assets/img/smtx--data-platform-brand-white.svg";

import {
  Menu,
  MenuBrand,
  MenuContent,
  MenuItem,
  MenuBottom,
  SubMenu,
  SubMenuGroup,
  SubMenuItem,
  Icon,
} from "semantix-design-system/components";

export const MENU = styled(Menu)`
  ${({ theme }) => theme.xs`
    width: 100vw;
    height: 72px;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
    align-items: center;
    justify-content: space-between;
    background-image: url(${MenuBG});
    background-repeat: no-repeat;
    background-size: cover;
    padding: 0 16px;
    ${() => theme.md`
      flex-flow: column nowrap;
      align-items: stretch;
      ustify-content: flex-start;
      width: 112px;
      height: 100vh;
      padding: 0;
    `}
  `}
`;
export const OGLogo = styled.img.attrs({ src: OpenGalaxyLogo })`
  max-width: 100px;
  margin: 0 auto;
  object-fit: contain;
`;

export const LINK = styled(Link)`
  text-decoration: none;
  font-weight: 600;
`;
export const MENUBRAND = styled(MenuBrand)`
  ${({ theme }) => theme.xs`
      display: none;    
    ${() => theme.md`
      display: block;
    `}
  `}
`;
export const MENUCONTENT = styled(MenuContent)`
  ${({ theme }) => theme.xs`
      overflow: hidden;
      overflow-x: auto;
      flex-grow: unset;
      ${() => theme.md`
        overflow-y: auto;
        flex-grow: 1;
      `}
  `}
`;
export const MENUITEM = styled(MenuItem)`
  span {
    font-size: 1.4rem;
    text-transform: capitalize;
  }
  text-transform: capitalize;
`;
export const MENUBOTTOM = styled(MenuBottom)`
  ${({ theme }) => theme.xs`
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    padding:0;
    margin:0;
    min-width: 140px;
    ${() => theme.md`
      flex-flow: column nowrap;
      margin: 24px 0;
      min-width: unset;
    `}
  `}
`;
export const SUBMENU = styled(SubMenu)`
  ${({ theme }) => theme.xs`

    &.idPpxN,
    &.dpkHva{
      z-index: 10;
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: calc(100% - 72px);
      overflow: hidden;    }
      .sub_menu_component{
      width:100%;
      h3{
        font-family: var(--primaryFonts);
        font-size: 1.8rem;
        font-weight: 600;
      }
      a{
        font-family: var(--secondaryFonts);
        font-size: 1.6rem;
        font-weight: 400;
      }
    }

    ${() => theme.md`
        &.idPpxN,
        &.dpkHva{
          position: absolute;
          left: 112px;
          height: 100%;
          width: auto;
        }
        .sub_menu_component {
            width: auto;
            min-width: 268px;
            max-width: 280px;
            box-shadow: 1px 1px 4px #ccc;
          }
    `}
  `}
`;
export const SUBMENUGROUP = styled(SubMenuGroup)``;
export const SUBMENUITEM = styled(SubMenuItem)`
  font-family: var(--secondaryFonts);
  font-size: 1.6rem;
  font-weight: 400;
  padding: 12px 21px;
`;
export const Navigation = styled.nav`
  ${({ theme }) => theme.xs`
      z-index: 10;
      height: auto;
      width: auto;
      position: relative;
    ${() => theme.md`
      height: 100vh;
      position: sticky;
      display: block;
      top: 0;
      bottom: 0;
      left: 0;
      box-shadow: 1px 1px 4px #ccc;
    `}
  `}
`;

export const Button = styled.button`
  background-color: transparent;
`;

export const ICON = styled(Icon)``;
