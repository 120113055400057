import { css } from "styled-components";

export default css`
  ${(props) => {
    const { theme } = props;
    return `
      scroll-behavior: smooth;
      scrollbar-width: thin;
      scrollbar-color: ${theme.colors?.primary_2} ${theme.colors?.white};
      ::-webkit-scrollbar {
        -webkit-appearance: none;
        background-color: ${theme.colors?.grey_5};
      }

      ::-webkit-scrollbar:vertical {
        width: 6px;
      }

      ::-webkit-scrollbar:horizontal {
        height: 6px;
      }

      ::-webkit-scrollbar-thumb {
        -webkit-appearance: none;
        background-color: ${theme.colors?.primary_2};
        border-radius: 0px;
        border:none;
      }

      ::-webkit-scrollbar-track {
        -webkit-appearance: none;
        border-radius: 0px;
        background-color: transparent;
      }
`;
  }};
`;
