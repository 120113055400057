import { useTranslation } from "react-i18next";

export default function LangSelectorViewModel() {
  const { i18n } = useTranslation(null, { useSuspense: false });
  const languages = Object.keys(i18n?.options?.resources || {});
  const { language: selectedLang } = i18n;

  const setLang = (lang) => (e) => {
    e.preventDefault();
    i18n.changeLanguage(lang);
  };

  return {
    selectedLang,
    languages,
    setLang,
  };
}
