/* eslint-disable prefer-const */
import { Api } from "Services";
import { Toast, Modal, highSercurityModalConfig } from "Utils";
import { getInitialProps } from "react-i18next";
import {
  getDeployments,
  getDeploymentsSuccess,
  getDeploymentsFailure,
  resetDeployments,
} from "../ducks/actions";

const { Inventory } = new Api();

export const getDeploymentsThunk = (accountId) => (dispatch, getStore) => {
  const { Token: token } = getStore();
  let { initialI18nStore, initialLanguage } = getInitialProps();
  let { deployments: g } = initialI18nStore[initialLanguage];
  let deployments = g?.deployments;

  dispatch(getDeployments());
  if (accountId) {
    return Inventory(token?.data?.idToken)
      .get(`/v1/accounts/${accountId}/deployments`)
      .then(({ data }) => {
        dispatch(getDeploymentsSuccess(data.entries));
      })
      .catch(async (err) => {
        let errorGet = await deployments?.alerts?.errorGet;
        await Toast.fire({
          icon: "error",
          title: errorGet,
        });
        await dispatch(getDeploymentsFailure(err));
        await dispatch(resetDeployments());
      });
  }
  return dispatch(getDeploymentsFailure("No account id"));
};

export const createDeployment = ({ history, accountId, template_id }) => (
  dispatch,
  getStore
) => {
  const { Token: token } = getStore();
  async function submitSuccess() {
    await Toast.fire({
      icon: "success",
      title: "Deployment added successfully",
    });
    await dispatch(getDeploymentsThunk(accountId));
    return history.push("/deployments");
  }
  async function submitError(error) {
    const { response, message } = await error;

    if (response?.data?.message) {
      return Toast.fire({
        icon: "error",
        title: response?.data?.message,
      });
    }

    if (message) {
      return Toast.fire({
        icon: "error",
        title: message,
      });
    }

    return Toast.fire({
      icon: "error",
      title: "Something went wrong.",
    });
  }

  Inventory(token?.data?.idToken)
    .post(`./v1/accounts/${accountId}/deployments`, { template_id })
    .then(submitSuccess)
    .catch(submitError);
};

export const deleteDdeployment = ({ deployment, account, alerts }) => (
  dispatch,
  getStore
) => {
  const { Token: token } = getStore();

  // succcess do Inventory.delete
  async function DeletingDeploymentSucces(res) {
    if (res?.status === 202) {
      await Toast.fire({
        icon: "success",
        title: alerts.deleting.success.msg,
      });
      return dispatch(getDeploymentsThunk(account.value));
    }
    return null;
  }
  // error do Inventory.delete
  async function DeletingDeploymentError(err) {
    const { response, message } = await err;

    if (response) {
      const errors = {
        403: "You have no permission to delete this deployment. Please contact your system administrator",
        400: "This deployment has not provisioned yet.",
        default: "An error has occurred. Please, try again.",
      };

      await Toast.fire({
        icon: "error",
        title: errors[response?.status || "default"],
      });

      return null;
    }

    if (message) {
      await Toast.fire({
        icon: "error",
        title: message,
      });
      return null;
    }

    return null;
  }
  // response do Modal
  function DeleteAlert(res) {
    if (res.isConfirmed) {
      return Inventory(token?.data?.idToken)
        .delete(`/v1/accounts/${account.value}/deployments/${deployment?.id}`)
        .then(DeletingDeploymentSucces)
        .catch(DeletingDeploymentError);
    }
  }
  return Modal.fire({
    icon: "warning",
    title: alerts.deleting.ask.title,
    html: alerts.deleting.ask.text,
    preConfirm: (attribute) => attribute === deployment.slug,
    ...highSercurityModalConfig,
    confirmButtonText: alerts.deleting.ask.buttons.confirm,
    cancelButtonText: alerts.deleting.ask.buttons.cancel,
  }).then(DeleteAlert);
};
