import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { ClearStore } from "Store";
import { logout, chekcIsAuth } from "Services";

export default function AuthorizedContainerViewModel() {
  const location = useLocation();
  const { data: token } = useSelector((S) => S.Token);

  function AuthContainerEffect() {
    (async function Mount() {
      await chekcIsAuth();
      const currentTime = new Date().getTime();
      const expiresIn = token?.expiresIn * 1000 + 36 * 10 ** 5;
      const isAuth = currentTime < expiresIn;

      if (!token?.idToken || !isAuth) {
        await ClearStore();
        return logout();
      }
    })();
  }

  return {
    location,
    AuthContainerEffect,
  };
}
