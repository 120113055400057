/* eslint-disable import/prefer-default-export */
import {
  setSelectedAccount,
  setSelectedAccountSuccess,
  setSelectedAccountFailure,
} from "../ducks/SelectedAccount";
import { getDeploymentsThunk } from "./deployments";
import { getUsersThunk } from "./users";

export const setSelectedAccountThunk = (account) => {
  const dispatcher = async (dispatch) => {
    if (account) {
      let timer;
      dispatch(setSelectedAccount());
      dispatch(getDeploymentsThunk(account.value));
      dispatch(getUsersThunk(account.value));
      (function Debounce() {
        clearTimeout(timer);
        timer = setTimeout(() => {
          dispatch(setSelectedAccountSuccess(account));
        }, 500);
      })();
      return dispatch(setSelectedAccountFailure());
    }
    return dispatch(setSelectedAccountFailure());
  };
  return dispatcher;
};
