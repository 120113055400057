import React from "react";
import { BrowserRouter as Router } from "react-router-dom";

import { ErrorBoundary } from "Containers";

import OpenGalaxyRoutes from "Routes";

export default function App() {
  return (
    <ErrorBoundary>
      <Router>
        <OpenGalaxyRoutes />
      </Router>
    </ErrorBoundary>
  );
}
