import styled from "styled-components";
import {
  Box,
  CardTitle,
  Paragraph,
  Button,
} from "semantix-design-system/components";

export const Img = styled.img``;
export const TextWrap = styled.div`
  width: auto;
  text-align: center;
  margin: 24px auto;
`;
export const BOX = styled(Box)`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: space-between;
  min-height: 32rem;
  padding: 24px 0 32px 0;
`;
export const TITLE = styled(CardTitle)`
  ${({ theme }) => theme.xs`
    font-family: var(--primaryFonts);
    font-size: 1.8rem;
    line-height: 1.25em;
    text-transform: none;
    font-weight: 700;
    color: ${theme.colors?.grey_1};
  `}
  ${({ theme }) => theme.md`
    font-size: 21px;
    line-height: 1.5em;
  `}
`;
export const PARAGRAPH = styled(Paragraph)`
  ${({ theme }) => theme.xs`
  font-family: var(--secondaryFonts);
  font-size: 1.6rem;
  line-height: 1.25em;
  text-align: center;
`}
  ${({ theme }) => theme.md`
  font-size: 16px;
  line-height: 1.5em;
`}
`;
export const BUTTON = styled(Button)`
  font-family: var(--secondaryFonts);
  font-size: 1.8rem;
  font-weight: 300;
  padding: 14px 18px;
  line-height: 1.5em;
  margin-top: 48px;
`;
