import React from "react";
import PropTypes from "prop-types";
import * as S from "./pageStrucuture.style";

export default function PageStructure({
  title,
  subtitle,
  children,
  headElement,
  className,
}) {
  return (
    <S.Page className={className}>
      <S.CONTAINER>
        <S.ROW>
          <S.COL xs={12} md={12}>
            <S.PageHead>
              <S.PageHeadCol>
                {title && <S.PAGETITLE>{title}</S.PAGETITLE>}
                {subtitle && <S.SECTIONTITLE>{subtitle}</S.SECTIONTITLE>}
              </S.PageHeadCol>
              <S.PageHeadCol>{headElement}</S.PageHeadCol>
            </S.PageHead>
          </S.COL>
        </S.ROW>
      </S.CONTAINER>
      <S.CONTAINER>{children}</S.CONTAINER>
    </S.Page>
  );
}

PageStructure.defaultProps = {
  title: null,
  subtitle: null,
  headElement: null,
  className: "",
};

PageStructure.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
    PropTypes.func,
  ]).isRequired,

  headElement: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
    PropTypes.func,
  ]),
};
