import styled from "styled-components";
import { Container, Row, Col } from "react-grid-system";
import { Loader } from "Common/Components";
import { Button, Icon, PageTitle } from "semantix-design-system/components";
import Images from "semantix-design-system/components/Contents/Images";
import OGLogo from "Assets/img/Logo_Open_Galaxy.png";

export const PortalContainer = styled.section``;
export const PortalHeader = styled.div`
  width: 100vw;
  height: 72px;
`;
export const LogoImg = styled.img.attrs({
  src: OGLogo,
  alt: "outter page Logo",
})`
  width: min(100vw, 112px);
  height: auto;
  position: relative;
  display: block;
  ${({ theme }) => theme.md`
      width: min(100vw, 200px);
    `}
`;
export const CONTAINER = styled(Container)`
  max-width: 1140px !important;
  height: 100%;
`;
export const ROW = styled(Row)`
  height: inherit !important;
`;
export const COL = styled(Col)`
  display: flex;
  align-items: center;
  &.justifyEnd {
    justify-content: flex-end;
  }
  &.justifyStart {
    justify-content: flex-start;
  }
  &.justifyBetween {
    justify-content: space-between;
  }
`;

export const BUTTON = styled(Button)``;
export const ICON = styled(Icon)``;
export const PAGETITLE = styled(PageTitle)`
  ${({ theme }) => theme.xs`
    color: ${theme.colors?.grey_3};
    font-family: var(--secondaryFonts);
    font-weight: 500;
  `}
`;
export const LOADER = styled(Loader)``;

export const IMAGES = styled(Images)`
  ${({ theme }) => theme.xs`
    margin: 0;
    padding-left: 16px;
    border-left: 1px solid ${theme.colors?.grey_4};
    justify-self: flex-start;
    flex: 0;
    display: flex;
    align-items: center;

    & > img {
      width: 32px;
      height: 32px;
    }

  ${() => theme.md`
      & > img {
        width: 48px;
        height: 48px;
      }

    `}
  `}
`;
