import styled from "styled-components";
import { Icon } from "semantix-design-system/components";

export const AccordeonWrap = styled.div`
  position: relative;
  width: auto;
  height: auto;
  font-family: "Whitney", "Opens Sans", sans-serif;
`;
export const Accordeon = styled.label`
  ${({ theme }) => theme.xs`
    border: 1px solid ${theme.colors?.primary_2};
    border-radius: 8px;
    width: 100%;
    height: (160px, 100%);
    display: flex;
    flex-flow: row wrap;
    position: relative;
    padding: 16px 24px;
    margin-bottom: 24px;
    overflow: hidden;
    transition: all 0.5s ease;
  `};
`;
export const AccordeonHeader = styled.div`
  ${({ theme }) => `
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    align-items center;
    justify-content: space-between;
    color: ${theme.colors?.grey_2};
    transition: all 0.5s ease;
    postion: relative;
    z-index: 1000;

  `}
`;
export const AccordeonTitle = styled.h3`
  font-weight: 500;
`;
export const AccordeonArrow = styled(Icon)`
  ${({ theme }) => `
    transform-origin: center;
    transition: all 0.5s ease;
    transform: rotate3d(1,1,0, 180deg);
    transform-style: preserve-3d;
    fill: ${theme.colors?.primary_2};
  `}
`;
export const AccordeonContent = styled.div`
  width: 100%;
  height: auto;
  max-height: 0px;
  line-height: 1.125em;
  margin-top: 0;
  transform-origin: top center;
  transform: scaleY(0);
  transform-style: preserve-3d;
  opacity: 0;
  line-height: 1.5em;
  overflow-x: hidden;
  overflow-y: auto;
  transition: all 1s ease;
  position: relative;
`;
export const AccordeonController = styled.input`
  ${({ theme }) => `
    visibility: visible;
    position absolute;
    &:checked{
      & ~ ${Accordeon}{
        border-color: ${theme.colors.primary_3};
        ${AccordeonHeader} {
          color: ${theme.colors?.primary_3};
          ${AccordeonArrow} {
            transform: rotate3d(1,1,0, 360deg);
            fill: ${theme.colors?.primary_3};
          }
        }

        ${AccordeonContent} {
          overflow-y: auto;
          max-height: 200px;
          transform: scaleY(1);
          line-height: 1.5em;
          opacity: 1;
          margin-top: 8px;
        }

      }
    }
  `}
`;
