const manageUsers = {
  backButton: "Voltar",
  title: {
    add: "Incluir usuário",
    edit: "Editar dados do usuário",
  },

  form: {
    submit: {
      add: "Incluir",
      edit: "Editar",
    },
    labels: {
      name: "Nome",
      email: "E-mail",
      role: "Funções",
    },
    errors: {
      required: "{{ field }} é obrigatório.",
      minLen: "No mínimo {{ len }} caracteres.",
      invalid: "{{ field }} é inválido",
      noOption: "Escolha uma das {{ field }} a seguir.",
    },
  },
};

export default manageUsers;
