import { css } from "styled-components";

const sizes = {
  xs: 320,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
};

export default Object.keys(sizes).reduce((acc, curr) => {
  acc[curr] = (...args) => css`
    @media (min-width: ${sizes[curr]}px) {
      ${css(...args)}
    }
  `;
  return acc;
}, {});

