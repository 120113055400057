import React, { useMemo } from "react";
import PropTypes from "prop-types";
import planets from "Assets/img/planets.svg";
import * as S from "./statusNull.style";

export default function StatusNull({
  image,
  title,
  text,
  action,
  actionText,
  hasAction,
}) {
  const RenderComponent = useMemo(
    () => (
      <S.BOX>
        <S.Img src={image} alt="status null image" />
        <S.TextWrap>
          <S.TITLE>{title}</S.TITLE>
          <S.PARAGRAPH>{text}</S.PARAGRAPH>
        </S.TextWrap>
        {hasAction && <S.BUTTON onClick={action}>{actionText}</S.BUTTON>}
      </S.BOX>
    ),
    [image, title, text, action, actionText, hasAction]
  );

  return RenderComponent;
}

StatusNull.defaultProps = {
  image: planets,
  title: "",
  text: "",
  actionText: "",
  action: () => false,
  hasAction: false,
};

StatusNull.propTypes = {
  image: PropTypes.string,
  hasAction: PropTypes.bool,
  title: PropTypes.string,
  text: PropTypes.string,
  actionText: PropTypes.string,
  action: PropTypes.func,
};
