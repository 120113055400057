import styled from "styled-components";

const transition = "transition: all 0.5s ease;";
export const Wrap = styled.div`
  ${({ w, h }) => `
    height: ${h || 181}px;
    width: ${w || 241}px;
  `}
`;
export const Label = styled.label`
  ${({ theme, w, h, name, id }) => `
    width: 100%;
    height: 100%;
    background: ${theme.colors?.white};
    padding:1px;
    border-radius: 4;
    overflow: hidden;
    display: flex;
    border-radius: 4px;
    align-items: center;
    justify-content: center;
    z-index: 2;
    color: ${theme.colors?.grey_3};
    position: relative;
    ${transition}
    &:before {
      content: '';
      width: 50px;
      height: 50px;
      position: absolute;
      display: none;
      left: -25px;
      top: -25px;
      box-shadow: 0px 0px 20px 15px ${theme.colors?.primary_3};
      z-index: 1;
      background: ${theme.colors?.primary_3};
      border-radius: 50%;
      animation: ${name + id}-hover ${
    (w / h) * 2000
  }ms cubic-bezier(1,1,0,0) infinite;
      ${transition};
      @keyframes ${name + id}-hover {
        0% {
          transform: translate(0, 0);
        }

        25% {
          transform: translate(${w}px, 0);
        }

        50% {
          transform: translate(${w}px, ${h}px);
        }

        75% {
          transform: translate(0, ${h}px);
        }

        100% {
          transform: translate(0, 0);
        }
      }
    }
      &:hover {
        cursor: pointer;
        &:before {
          display: block;
        }
      }
    }

  `}
`;
export const LabelContent = styled.div`
  ${({ theme }) => `
  width: inherit;
  height: inherit;
  border: 1px solid ${theme.colors?.grey_6};
  background: ${theme.colors?.white};
  border-radius: 3px;
  display: block;
  z-index: 2;
  padding: 16px 12px;
  color: ${theme.colors?.grey_2};
  position: relative;
  ${transition}
  }

  `}
`;
export const Title = styled.h2`
  font-size: 1.8rem;
  font-weight: 600;
  width: auto;
  text-align: left;
  margin: 0 auto;
  line-height: 1.35em;
  margin-bottom: 12px;
`;
export const Text = styled.p`
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.5em;
  white-space: pre-line;
  word-break: keep-all;
`;
export const Input = styled.input`
  position: absolute;
  visibilty: hidden;

  &:checked ~ ${Label} {
    ${({ theme }) => `
      ${LabelContent} {
        max-width: inherit;
        max-height: inherit;
        transform: scale(1);
        color: ${theme.colors?.white};
        background: ${theme.colors?.primary_3};
        border-color: ${theme.colors?.primary_3};
      }
      &:before{
        display: block;
        animation-play-state: paused;
        left: 0px;
        top: 0px;
        width: 100%;
        height: 100%;
        border-radius: 0%;
      }
   `}
  }
`;
