import React, { useMemo, forwardRef } from "react";
import PropTypes from "prop-types";
import * as S from "./card.style";

function Card({ title, children, className }, ref) {
  const RenderCard = useMemo(
    () => (
      <S.BOX ref={ref && ref} className={className}>
        <S.TITLE>{title}</S.TITLE>
        <S.CardContent>{children}</S.CardContent>
      </S.BOX>
    ),
    [title, children, className, ref]
  );

  return RenderCard;
}

export default forwardRef(Card);

Card.defaultProps = {
  title: "",
  className: "",
};

Card.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
    PropTypes.func,
  ]).isRequired,
};
