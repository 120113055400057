const navigation = {
  deployments: "Deployments",
  logout: "Logout",
  settings: "Settings",
  siblings: {
    accounts: "Accounts",
    users: "Users",
  },
};

export default navigation;
