import { TriggerError } from "Utils";

const INITIAL_STATE = {
  loading: false,
  data: [],
  error: {},
};

const GET_DEPLOYMENTS_REQUEST = "GET_DEPLOYMENTS_REQUEST";
const GET_DEPLOYMENTS_SUCCESS = "GET_DEPLOYMENTS_SUCCESS";
const GET_DEPLOYMENTS_FAILURE = "GET_DEPLOYMENTS_FAILURE";
const RESET_DEPLOYMENTS = "RESET_DEPLOYMENTS";

export const getDeployments = () => ({
  type: GET_DEPLOYMENTS_REQUEST,
  payload: null,
});

export const getDeploymentsSuccess = (value) => ({
  type: GET_DEPLOYMENTS_SUCCESS,
  payload: value,
});

export const getDeploymentsFailure = (error) => {
  TriggerError(GET_DEPLOYMENTS_FAILURE, error);

  return {
    type: GET_DEPLOYMENTS_FAILURE,
    payload: { error },
  };
};

export const resetDeployments = () => ({
  type: RESET_DEPLOYMENTS,
  payload: [],
});

export default function Deployments(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_DEPLOYMENTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_DEPLOYMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case GET_DEPLOYMENTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case RESET_DEPLOYMENTS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
}
