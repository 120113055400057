import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import theme from "semantix-design-system/components/theme";

const SwalReact = withReactContent(Swal);

export const Modal = SwalReact;

export const Toast = Swal.mixin({
  toast: true,
  position: "top-right",
  showConfirmButton: false,
  width: "min(100vw - 16px, 330px)",
  timer: 3000,
  timerProgressBar: true,
  showCloseButton: true,
  didOpen: (toast) => {
    toast.addEventListener("mouseenter", SwalReact.stopTimer);
    toast.addEventListener("mouseleave", SwalReact.resumeTimer);
  },
});

export const modalWarnConfig = {
  showCancelButton: true,
  reverseButtons: true,
  cancelButtonText: "No",
  cancelButtonColor: theme.colors?.primary_2,
  confirmButtonText: "Yes, continue",
  confirmButtonColor: theme.colors?.error,
  allowOutsideClick: false,
  allowEscapeKey: false,
  allowEnterKey: false,
};

export const highSercurityModalConfig = {
  input: "text",
  showLoaderOnConfirm: true,
  showCancelButton: true,
  reverseButtons: true,
  allowOutsideClick: () => !Swal.isLoading(),
  inputAttributes: {
    autocapitalize: "off",
  },
};
