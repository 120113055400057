import styled from "styled-components";
import { Scrollbar } from "Styles";

const inputAttrs = {
  type: "text",
};

const transition = `
 transition-duration: 250ms;
 transition-timing-function: ease-in-out;
`;

export const DropArrow = styled.div`
  ${({ theme }) => `
    width: 12px;
    height: 12px;
    margin: 9px;
    background: transparent;
    border-style: solid;
    border-left-width: 3px;
    border-bottom-width: 3px;
    border-color: ${theme.colors?.grey_4};
    border-bottom-left-radius: 2px;
    border-top-right-radius: 2px;
    transform: rotate(-45deg);
    transform-origin: center;
    transform-styled: preserve-3d;
    position: relative;
    top: -4px;

    ${transition};
    transition-property: top, transform, border-color;
    transition-duration: 0, 0, 250ms;
    transition-delay: 0, 250ms, 500ms;
  `}
`;
export const DropboxList = styled.ul`
  ${({ theme }) => `
  position: absolute;
  display: block;
  max-height: 0px;
  width: 100%;
  transform: scaleY(0);
  transform-origin: top;
  ${transition};
  transition-property: transform, box-shadow;
  transition-delay: 0;
  transition-duration: 250ms, 0;
  z-index: 0;
  list-style-type: none;
  font-size: 1.8rem;
  box-shadow: 0 0 4px 0px ${theme.colors?.grey_6},
              0 0 12px 0px ${theme.colors?.grey_4};
  `}
  ${Scrollbar};
`;
export const DropboxItem = styled.li`
  width: 100%;
  opacity: 0;
  transition-property: opacity;
`;
export const SupText = styled.span`
  ${({ theme }) => `
    font-family: var(--secondaryFonts);
    font-size: 1rem;
    color: ${theme.colors?.grey_4};
    font-weight: 500;
    letter-spacing: 0.03rem;
    position: absolute;
    display: block;
    width: 100%;
    left: 0;
    top: -4px;
  `}
`;
export const DropboxWrap = styled.div`
  ${({ theme }) => `
  position: relative;
  cursor:pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-flow: row nowrap;
  width: fit-content;
   &:focus-within{
    ${DropboxList} {
      max-height: 180px;
      overflow-y: auto;
      transform: scaleY(1);
      z-index: 100;
      top: calc(100% + 4px);
      & > ${DropboxItem} {
        opacity: 1;
      }
    }
    ${SupText}{
      color: ${theme.colors?.primary_3};
    }
    ${DropArrow}{
      border-color: ${theme.colors?.primary_3};
    }
  }
  `}
`;
export const DropboxLabel = styled.label`
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  position: relative;
  align-items: center;
`;
export const DropboxButton = styled.button`
  ${({ theme }) => `
  width: inherit;
  padding: 12px 16px;
  background: ${theme.colors?.white};
  font-family: var(--secondaryFonts);
  font-size: 1.8rem;
  font-weight: 400;
  ${transition};
  transition-property: background, color;
  color: ${theme.colors?.grey_1};
  &:hover,
  &:focus {
    background: ${theme.colors?.grey_5};
    color: #000;
  }
  `}
`;

export const DropboxInput = styled.input.attrs(inputAttrs)`
  ${({ theme }) => `
    color: ${theme.colors?.grey_1};
    background: white;
    flex: auto;
    min-width: 96px;
    width: calc(var(--searchInputSize) * 7px + 16px);
    max-width: 100%;
    padding: 12px 16px;
    ::selection{
      background: transparent;
      color: ${theme.colors?.primary_3};
      width: 100%;
      height: 100%;
    }
  `}
`;
