/* eslint-disable react-hooks/exhaustive-deps */

import React, { useMemo } from "react";
import PropTypes from "prop-types";
import AccountsSelectorViewModel from "./accountSelector";
import * as S from "./accountSelector.style";

export default function AccountsSelector({ className }) {
  const vm = new AccountsSelectorViewModel({ useMemo });

  const renderSelector = useMemo(() => {
    if (vm.account?.loading) {
      return <S.ELLIPSISLOADER />;
    }

    if (Object.keys(vm.account?.data)?.length) {
      return (
        <S.DROPBOX
          options={vm.options}
          placeholder={vm.t("label")}
          selected={vm.account?.data}
          onSelect={vm.handleSelect}
          className={className}
        />
      );
    }

    return null;
  }, [vm.account, vm.selectedLang]);

  return renderSelector;
}

AccountsSelector.defaultProps = {
  className: "",
};

AccountsSelector.propTypes = {
  className: PropTypes.string,
};
