import { TriggerError } from "Utils";

const INITIAL_STATE = {
  loading: false,
  data: [],
  error: null,
};

const GET_ROLES_REQUEST = "GET_ROLES_REQUEST";
const GET_ROLES_SUCCESS = "GET_ROLES_SUCCESS";
const GET_ROLES_FAILURE = "GET_ROLES_FAILURE";

export const getRoles = () => ({
  type: GET_ROLES_REQUEST,
});

export const getRolesSuccess = (value) => ({
  type: GET_ROLES_SUCCESS,
  payload: value,
});

export const getRolesFailure = (error) => {
  TriggerError(GET_ROLES_FAILURE, error);
  return {
    type: GET_ROLES_FAILURE,
    payload: { error },
  };
};

export default function Roles(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_ROLES_REQUEST:
      return { ...state };
    case GET_ROLES_SUCCESS:
      return { ...state, data: action.payload };
    case GET_ROLES_FAILURE:
      return { ...state, error: action.payload.error };
    default:
      return { ...state };
  }
}
