import styled from "styled-components";
import { Col } from "react-grid-system";
import { LanguageSelector } from "Components";
import { AuthorizedHeader, Navigation } from "Common/Components";
import { Scrollbar } from "Styles";

export const NAVIGATION = styled(Navigation)`
  ${({ theme }) => theme.xs`
    order: 2;
    ${() => theme.md`order:unset;`}
  `}
`;

export const Section = styled.section`
  ${({ theme }) => theme.xs`
      width: 100vw;
      height: fill-available;
      display: flex;
      flex-flow: column nowrap;
      box-sizing: border-box;
      position: relative;
      ${() => theme.md`
        align-items: stretch;
        flex-flow: row nowrap;
      `}
  `}
`;

export const Div = styled.div`
  ${({ theme }) => theme.xs`
    margin: 0 auto;
    width: 100%;
    max-height: 100vh;
    order: 1;
    overflow-x: hidden;
    overflow-y: auto;
    overflow-x: hidden;
      ${Scrollbar};
    ${() => theme.md`order:unset;`}
  `}
`;

export const COL = styled(Col)``;
export const HEADER = styled(AuthorizedHeader)``;
export const LANGSELECTOR = styled(LanguageSelector)``;
