import styled from "styled-components";
import { UnauthorizedHeader, UnauthorizedFooter } from "Common/Components";

export const HEADER = styled(UnauthorizedHeader)``;
export const FOOTER = styled(UnauthorizedFooter)``;

export const Section = styled.section`
  width: 100%;
  min-height: calc(100vh - 120px);
`;
