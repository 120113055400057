import React from "react";
import * as S from "./unauthorizedFooter.style";

export default function UnauthorizedFooter() {
  return (
    <S.Footer>
      <S.CONTAINER>
        <S.ROW>
          <S.COL xs={10} md={4} offset={{ xs: 1, md: 4 }}>
            <S.CopyTxt>
              <strong>&copy;</strong> Copyright {new Date().getFullYear()}{" "}
              Semantix
            </S.CopyTxt>
          </S.COL>
        </S.ROW>
      </S.CONTAINER>
    </S.Footer>
  );
}
