const flex = `
&.flex{
  display: flex ;
}
&.inlineFlex{
  display: inline-flex ;
}
`;

const flow = `
&.flowRW {
flex-flow: row wrap ;
}
&.flowCW {
flex-flow: column wrap ;
}
&.flowRNW {
flex-flow: row nowrap ;
}
&.flowCNW {
flex-flow: column nowrap ;
}
`;

const flexAlign = `
&.alignFS {
align-items: flex-start ;
}
&.alignFE {
align-items: flex-end ;
}
&.alignS {
align-items: stretch ;
}
&.alignC {
align-items: center ;
}

`;

const flexJustify = `
&.justFS {
justify-content: flex-start ;
}
&.justFE {
justify-content: flex-end ;
}
&.justSB {
justify-content: space-between ;
}
&.justSE {
justify-content: space-evenly ;
}
&.justSA {
justify-content: space-arround ;
}
`;

export default function flexBox() {
  return {
    flex,
    flow,
    flexAlign,
    flexJustify,
  };
}
