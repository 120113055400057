/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useMemo } from "react";
import PropTypes from "prop-types";
import AccordionTabViewModel from "./accordeonWithTabs";
import * as S from "./accordeonWithTabs.style";

export default function AccordionTab({
  children,
  id,
  icon,
  actions,
  status,
  alertStatus,
}) {
  const [actived, setActived] = useState(null);
  const [child, setChild] = useState(null);
  const vm = new AccordionTabViewModel({ setActived, setChild });

  const Tabs = useMemo(
    () =>
      children?.map((c) => (
        <S.AccordionButton
          key={c?.props?.accordion_child}
          onClick={vm.tabAction({ id, child: c?.props?.accordion_child })}
          className={c?.props?.accordion_child === child ? "active" : ""}
        >
          <S.AccordionChildTab>{c?.props?.accordion_child}</S.AccordionChildTab>
          {c.props.alert && (
            <S.AccordionAlert alerts={alertStatus} status={status}>
              {c.props?.alert}
            </S.AccordionAlert>
          )}
        </S.AccordionButton>
      )),
    [children, vm, id, child, alertStatus, status]
  );

  const content = useMemo(
    () => children?.filter((c) => c.props?.accordion_child === child),
    [child, children]
  );

  const renderActions = useMemo(
    () =>
      !!actions?.length && (
        <S.ActionsContainer>
          <S.ActionsIcon>
            <S.Circle cx="6" />
            <S.Circle cx="24" />
            <S.Circle cx="42" />
          </S.ActionsIcon>
          <S.ActionsWrap>
            <S.ActionsList>
              {actions.map(({ label, action, danger, disabled }) => (
                <S.ActionsButton
                  key={label}
                  onClick={action}
                  danger={danger}
                  isDisabled={disabled}
                  disabled={disabled}
                >
                  {label}
                </S.ActionsButton>
              ))}
            </S.ActionsList>
          </S.ActionsWrap>
        </S.ActionsContainer>
      ),
    [actions]
  );

  return (
    <S.Accordion className={actived === id ? "active" : ""}>
      <S.AccordionWrap>
        {icon && <S.IMAGES type={icon} />}
        <S.AccordionTabs>{Tabs}</S.AccordionTabs>
        {renderActions}
      </S.AccordionWrap>
      {content && <S.AccordionContent>{content}</S.AccordionContent>}
    </S.Accordion>
  );
}

AccordionTab.defaultProps = {
  id: null,
  icon: null,
  status: null,
  alertStatus: {},
  actions: [
    {
      label: "teste_1",
      action: (e) => e,
      danger: false,
    },
  ],
};

AccordionTab.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
    PropTypes.func,
  ]).isRequired,
  id: PropTypes.string,
  icon: PropTypes.string,
  alertStatus: PropTypes.object,
  status: PropTypes.string,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      action: PropTypes.func,
      danger: PropTypes.Bool,
      disabled: PropTypes.bool,
    })
  ),
};
