import styled from "styled-components";
import { Icon } from "semantix-design-system/components";

export const Wrap = styled.div`
  ${({ theme }) => theme.xs`
    width: auto;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    height: auto;
    position: relative;
    ${() => theme.md`

    `}
  `}
`;
export const Label = styled.label``;
export const Input = styled.input`
  ${(props) => props.theme.xs`
    width: calc(64px + ${props.defaultValue?.length} * 7px);
    color:  ${props.theme?.colors?.grey_1};
    font-size: 1.6rem;

    font-weight: 600;
    overflow: hidden;
    white-space: nowrap;
    letter-spacing: 0.3px;
    text-overflow: ellipsis;
    border: none;
    position: relative;
    letter-space: 0.3px;
    width: calc(var(--inputWidth) - 24px);
    min-width: 48px;
    max-width: 116px;
    transition: all 0.25s ease;
    ${() => props.theme.md`
      min-width: 64px;
      font-size: 1.8rem;
      max-width: 100%;
      width: calc(var(--inputWidth) - 6px);
    `}
  `}
`;

export const DataList = styled.datalist`
  appearance: none;
`;

export const Option = styled.option`
  appearance: none;
`;

export const ICON = styled(Icon)`
  ${({ theme }) => `
      width: 18px;
      height: 18px;
      path{
        fill: ${theme.colors?.primary_2}
      }
    `}
`;
