import styled from "styled-components";
import { PageTitle, SectionTitle } from "semantix-design-system/components";
import { Container, Row, Col } from "react-grid-system";

export const PAGETITLE = styled(PageTitle)`
  ${({ theme }) => theme.xs`
    font-family: var(--primaryFonts);
    font-size: 3rem;
  `}
`;
export const SECTIONTITLE = styled(SectionTitle)`
  ${({ theme }) => theme.xs`
     font-family: var(--primaryFonts);
     font-size: 2.4rem;
  `}
`;
export const CONTAINER = styled(Container)`
  max-width: 1140px !important;
`;
export const ROW = styled(Row)``;
export const COL = styled(Col)``;
export const Page = styled.div`
  margin: 0 auto;
  min-width: 100%;
`;

export const PageHead = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
`;
export const PageHeadCol = styled.div`
  margin: 0;
  width: auto;
  display: grid;
  place-items: center;
`;
