const manageUsers = {
  backButton: "Volver",
  title: {
    add: "Agregar usuario",
    edit: "Editar usuario",
  },
  form: {
    submit: {
      add: "Agregar",
      edit: "Editar",
    },
    labels: {
      name: "Nombre",
      email: "E-mail",
      role: "Funciones",
    },
    errors: {
      required: "{{ field }} es requerido.",
      minLen: "Al menos {{ len }} caracteres.",
      invalid: "{{ field }} es invalido",
      noOption: "Elija al menos una de las {{ field }} seguintes.",
    },
  },
};

export default manageUsers;
