const accounts = {
  title: "Ajustes",
  subtitle: "Cuentas",
  newAccount: "Nueva cuenta",
  accordeon: {
    tab_1: {},
    tab_2: {},
    actions: {
      delete: "Borrar",
    },
    alerts: {
      delete: {
        ask: {
          title: "Borrar cuenta",
          text:
            "<p>¿De verdad quieres eliminar la cuenta <b>{{ accountName }}</b>?</p> <p>Todos los recursos proporcionados por esa cuenta también se eliminarán..</p>",
          buttons: {
            confirm: "Si, continuar",
            cancel: "No, cancelar",
          },
        },
        success: {
          msg: "La cuenta {{ accountName }} se ha eliminada correctamente.",
        },
      },
    },
  },
};

export default accounts;
