/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react";
import { createPortal } from "react-dom";
import PropTypes from "prop-types";
import PortalViewModel from "./portal";
import * as S from "./portal.style";

export default function Portal({ children, onClose, name }) {
  const [element, setElement] = useState(null);
  const vm = PortalViewModel({ element, setElement });

  useEffect(vm.InitialEffect, []);
  useEffect(vm.PortalEffect, [element]);

  const portalWindow = useMemo(() => {
    const brands = vm.proxyIcons[name?.toLowerCase()];
    const icon = Object.create(S.ICON);
    icon.defaultProps = { type: "Close" };
    return (
      <S.PortalContainer>
        <S.PortalHeader>
          <S.CONTAINER>
            <S.ROW align="center" justify="between">
              <S.COL xs={3} className="justifyBetween">
                <S.LogoImg />
                {brands && <S.IMAGES type={brands} />}
              </S.COL>
              {name ? (
                <S.COL xs={8} className="justifyStart">
                  <S.PAGETITLE>{name}</S.PAGETITLE>
                </S.COL>
              ) : null}
              <S.COL xs={1} className="justifyEnd">
                <S.BUTTON
                  type="button"
                  icon={icon}
                  kind="cancel"
                  onClick={vm.handleClose(onClose, name)}
                />
              </S.COL>
            </S.ROW>
          </S.CONTAINER>
        </S.PortalHeader>
        {children || <S.LOADER full />}
      </S.PortalContainer>
    );
  }, [children, vm.handleClose, onClose, name]);

  return element ? createPortal(portalWindow, element) : null;
}
Portal.defaultProps = {
  onClose: null,
  name: null,
};
Portal.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
    PropTypes.func,
  ]).isRequired,
  onClose: PropTypes.func,
  name: PropTypes.string,
};
