import styled from "styled-components";
import { Dropbox, EllipsisLoader } from "Common/Components";

export const DROPBOX = styled(Dropbox)`
  ${({ theme }) => theme.xs`
    input[type="text"] {
      padding: 0;
      font-family: var(--sercondaryFonts);
      font-size: 2.4rem;
      font-weight: 600;
      width: calc(100% - 36px);
    }
    label{
      border-bottom: 1px solid ${theme.colors?.grey_4};
      width: 100%;
    }
    ${() => theme.md`
      input[type="text"]{
        min-width: 250px;
      }
    `}
  `}
`;

export const ELLIPSISLOADER = styled(EllipsisLoader)``;
