const newAccount = {
  backLink: "Voltar",
  title: "Nova conta",
  continueLink: "Continuar",
  form: {
    fields: {
      labels: {
        account: "Conta",
      },
      errors: {
        required: "{{ field }} é obrigatório.",
        minLen: "No mínimo {{ len }} caracteres.",
      },
    },
    buttons: {
      submit: "ok",
    },
  },
};

export default newAccount;
